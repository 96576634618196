import React, { useState, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  Modifier,
  RichUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import WYSIWYGViewer from "./WYSIWYGViewer";
import { options } from "../modules/_text_editor";
import "../css/draft_rtf.css";
const TextEditor = ({
  value,
  handleFieldChange,
  handleSaveToAdditionalComments,
  html,
  color,
  id,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [defaultEditorState, setDefaultEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    let rawContentState;
    if (value && typeof value === "string") {
      console.log("value is string");
      rawContentState = //convert from plain text to draftjs content
        htmlToDraft(value)?.contentBlocks?.length > 0
          ? convertToRaw(
              ContentState.createFromBlockArray(
                htmlToDraft(value).contentBlocks
              )
            )
          : null;
    } else if (value && typeof value === "object") {
      console.log("value is object");
      rawContentState = value;
    } else {
      console.log("value is null");
      rawContentState = null;
    }
    console.log("rawContentState", rawContentState);
    if (rawContentState) {
      if (!rawContentState.entityMap) {
        rawContentState.entityMap = {};
        // defaultEditorState.entityMap = {};
      }
      const contentState = convertFromRaw(rawContentState);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } else {
      console.log("rawContentState is null");
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  useEffect(() => {
    onSave(); //this will kick off autosave when the component loads
  }, [editorState]);

  const onEditorStateChange = (newEditorState) => {
    // Only update the editorState if the text has changed
    // if (
    //   newEditorState.getCurrentContent().getPlainText() !==
    //   editorState.getCurrentContent().getPlainText()
    // ) {
    //   setEditorState(newEditorState);
    //   //   onSave(newEditorState);
    // }
    setEditorState(newEditorState);
    // to prevent autosave from kicking on
    //onSave();
  };

  const onSave = (e) => {
    const contentState = editorState?.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    if (editorState !== undefined && editorState !== null) {
      console.log(
        "editorState plain text",
        editorState.getCurrentContent().getPlainText()
      );
      const markup = draftToHtml(rawContent);

      const htmlState = rawContent && stateToHTML(contentState);
      console.log(htmlState, "html state");
      console.log(markup, "html markup");
      handleFieldChange(
        e,
        rawContent,
        html ? markup : editorState.getCurrentContent().getPlainText()
      );
      // handleSaveToAdditionalComments(
      //   e,
      //   editorState.getCurrentContent().getPlainText()
      // );
    }
  };

  const handlePastedText = (text, html, editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Parse the pasted text and create a new ContentState
    const newContentState = parsePastedText(text);

    // Insert the new ContentState at the current selection
    const newEditorState = EditorState.push(
      editorState,
      Modifier.replaceWithFragment(
        contentState,
        selection,
        newContentState.getBlockMap()
      ),
      "insert-fragment"
    );

    // Apply the 'unordered-list-item' block type to the new ContentState
    const finalEditorState = RichUtils.toggleBlockType(
      newEditorState,
      "unordered-list-item"
    );

    onEditorStateChange(finalEditorState);

    return "handled";
  };

  const parsePastedText = (text) => {
    // Parse the pasted text and create a new ContentState
    // This is a simplified example, you might need to adjust this to handle different types of pasted content
    const blocks = text.split("\n").map((line) => ({
      type: "unordered-list-item",
      text: line,
    }));

    return ContentState.createFromBlockArray(blocks);
  };

  return (
    <div className="">
      <div className="">
        <Editor
          editorState={editorState}
          //   initialContentState={editorState}
          //   handlePastedText={handlePastedText}
          toolbarClassName={
            color && color === "gray" ? "gray-toolbar" : "rdw-editor-toolbar"
          } // Conditionally add the toolbarClassName prop
          // {...(color === "gray" && { toolbarClassName: "custom-toolbar" })} // Conditionally add the toolbarClassName prop
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              //   "blockType",
              "fontFamily",
              "fontSize",

              "list",
              "textAlign",
              "link",
              "colorPicker",
              //   "embedded",
            ],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
            },
            fontFamily: {
              options: [
                "Arial",
                "Georgia",
                "Impact",
                "Tahoma",
                "Times New Roman",
                "Verdana",
              ],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["unordered"],
              //   options: ["unordered", "ordered", "indent", "outdent"],
            },
            // list: { inDropdown: false },
            // textAlign: { inDropdown: false },
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["left", "center", "right", "justify"],
              //   left: { icon: "left", className: undefined },
              //   center: { icon: "center", className: undefined },
              //   right: { icon: "right", className: undefined },
              //   justify: { icon: "justify", className: undefined },
            },
            link: { inDropdown: false },
            history: { inDropdown: true },
          }}
        />
        {/* <button onClick={onSave}>Save</button> */}
      </div>
      <div>
        {/* display the text in html format */}
        {/* <WYSIWYGViewer editorState={editorState} /> */}
      </div>
    </div>
  );
};

export default TextEditor;
