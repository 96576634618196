import React from "react";

const RequirementsHeader = () => {
  return (
    <div className="mt-6">
      {" "}
      <div className="col-span-6 text-xl font-medium text-slate-900">
        Other Requirements
      </div>
      <div className="col-span-6 text-md">
        This section lists any additional requirements the requestor may have.
        These requirements are in addition to the above assumptions. If answers
        are provided, these requirements should be incorporated into all
        versions of your bid.
      </div>
    </div>
  );
};

export default RequirementsHeader;
