export default function Chip({ value, index, chipColor }) {
  const getChipColor = () => {
    return `bg-${chipColor}-800 text-${chipColor}-100`;
  };
  return (
    <span
      key={index}
      className={`mr-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
        chipColor ? getChipColor() : "bg-slate-100 text-slate-800"
      }`}
    >
      {value}
    </span>
  );
}
