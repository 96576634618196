import { Fragment, useEffect, useMemo, useState } from "react";

import Tip from "../../../components/Tip";
import { Section } from "./DataEntry";
import all_assumptions from "../../../config/assumptions";
import { utils } from "../../../modules/_utils";
import { Paragraph } from "../../../components/Typography/Paragraph";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import { Icon } from "@iconify/react";
export default function Assumptions({
  service,
  solicitation,
  groupIndex,
  cohort,
  entries,
}) {
  const [data, setData] = useState({});
  const [assumptions, setAssumptions] = useState([]);
  const colSpan = 2;
  const rowspan = 1;

  useEffect(() => {
    //Future Improvement:  Pass opts2 as a prop to this component for all services
    if (!solicitation) return;
    let opts2 = solicitation.services.find(
      (el) => el.service === service
    ).opts2;
    console.log("AssumptionJS, opts2 is ", opts2);
    console.log("AssumptionJS entries", entries);
    console.log("AssumptionJS groupIndex", groupIndex);

    if (entries) {
      console.log("AssumptionJS entries", entries);
      opts2 = entries;
    } else {
      console.log("AssumptionJS no groupIndex found");
      console.log("AssumptionJS selected opts2", opts2);
    }
    console.log("AssumptionJS setting Data to", opts2);
    setData(opts2);

    console.log("all_assumptions", all_assumptions.services);
    setAssumptions(all_assumptions.services[service].fields);
  }, [solicitation]);

  const cleanUpValues = (arrValues, label = "") => {
    console.log("cleanUpValues", arrValues);
    if (!arrValues) return;
    if (!Array.isArray(arrValues)) return arrValues;
    let newValues = [];
    arrValues.map((value) => {
      let newVal = utils.cleanUpKeys(value);
      newValues.push(newVal + " " + label);
    });
    console.log("cleanUpValues new", newValues);
    return newValues;
  };

  return (
    <div className="p-4 rounded bg-gray-50 border border-gray-200 ">
      <div className="col-span-6 text-xl font-medium text-slate-900">
        {utils.cleanUpKeys(service)} Assumptions
      </div>
      <div className="col-span-6 text-md">
        These are the assumptions that must be used to generate your quote.
        Please read them carefully.
      </div>
      <Tip color="tertiary" colSpan={"full"}>
        Your quote should only include pricing for the services requested in
        this form. If you provide extra benefits included in your price, you may
        call that out in the Additional Information area.
      </Tip>
      {assumptions.map((assumption, index) => {
        if (
          assumption.type === "warning" &&
          data[assumption.name] &&
          data[assumption.name] !== false
        ) {
          return (
            // <Tip color="red" colSpan={"full"}>
            <div className="col-span-full border-l-4 rounded border-l-red-500 bg-white shadow p-1.5  mb-6 mt-6">
              <div>
                <span className="ml-1 font-normal text-sm">
                  {assumption.message(data[assumption.name], service)}
                </span>
              </div>
            </div>
          );
        }
      })}

      <div id="assumptions-list" className="w-full  mt-4">
        <div className="col-span-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 ">
          {assumptions.map((assumption, index) => {
            if (data && data[assumption.name] === "") return null;
            if (
              ((data && data[assumption.name]) ||
                assumption.type === "system") &&
              assumption.type !== "warning" &&
              assumption.type !== "text" &&
              assumption.type !== "list"
            ) {
              return (
                <div
                  className={`col-span-1 bg-white shadow row-span-${rowspan}  rounded `}
                >
                  <div className="px-5 pt-5 pb-5">
                    <div className="flex items-start">
                      <div className="flex flex-wrap text-2xl font-semibold text-slate-800 mr-3 mb-2">
                        {Array.isArray(data[assumption.name]) ? (
                          <ReadOnlyAnswer
                            type={"chips"}
                            answer={data[assumption.name]}
                          />
                        ) : assumption.data_type === "boolean" ? (
                          data[assumption.name] ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : !isNaN(Number(data[assumption.name])) ? (
                          Number(data[assumption.name]).toLocaleString()
                        ) : assumption.type == "system" ? (
                          assumption.value &&
                          utils.cleanUpKeys(assumption.value.toLocaleString())
                        ) : (
                          <span className="capitalize">
                            {data[assumption.name] &&
                              utils.cleanUpKeys(data[assumption.name])}
                          </span>
                        )}

                        {assumption.label != "Value" &&
                          assumption.label != "Documents" &&
                          assumption.label}
                      </div>
                    </div>
                    <div className="inline-flex w-fit align-middle  text-md font-semibold text-slate-600 mb-2 ml-0">
                      {assumption.title}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div id="assumption-text-lists" className="w-full  mt-4">
        {assumptions &&
          assumptions.map((assumption, index) => {
            if (
              (assumption.type === "text" || assumption.type === "list") &&
              data[assumption.name] &&
              data[assumption.name] !== false
            ) {
              return (
                <div
                  className={`mb-4 col-span-2 bg-white shadow row-span-${rowspan}  rounded `}
                >
                  <div className="px-5 pt-5 pb-5">
                    <div className="text-2xl font-semibold text-slate-800 inline-flex w-fit align-middle  mb-2 ml-0">
                      {assumption.title}
                    </div>
                    <div className="flex items-start">
                      <div className="  text-2xl font-semibold text-slate-800 mr-3 mb-2">
                        {Array.isArray(data[assumption.name]) ? (
                          <ReadOnlyAnswer
                            type={"chips"}
                            answer={cleanUpValues(
                              data[assumption.name],
                              assumption.label || ""
                            )}
                          />
                        ) : (
                          <Paragraph className={"font-normal"}>
                            {assumption.value ? (
                              <span className="">
                                {assumption.value(
                                  data[assumption.name],
                                  service
                                )}
                              </span>
                            ) : (
                              <span className="">{data[assumption.name]}</span>
                            )}
                            {/* {assumption.message(data[assumption.name], service)}
                            {data && data[assumption.name]} */}
                          </Paragraph>
                        )}
                      </div>
                    </div>
                    {assumption.description && (
                      <div className="pt-4 w-fit align-middle  text-sm text-slate-600 mb-2 ml-0">
                        <Icon
                          icon="fluent-color:error-circle-16"
                          className="w-6 h-6 inline-block mr-1 text-red-500"
                        />{" "}
                        {assumption.description}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}
