import React from "react";

import { Icon } from "@iconify/react";
import Tooltip from "./Tooltip";

export default function QuestionToolTip(props) {
  const { field, text, children, color, position, size, solid, ...rest } =
    props;

  return (
    <Tooltip
      className={
        " text-xs text-slate-500 whitespace-normal font-normal normal-case inline-flex"
      }
      position={position ? position : "top"}
      size={size ? size : "md"}
      description={text}
      title={field}
      html={props.html}
    >
      <Icon
        icon={solid ? "carbon:help-filled" : "carbon:help"}
        className={`ml-1 -mt-2 h-4 w-4 text-${
          color ? (color == "white" ? "white" : color + "-600") : "brand-600"
        }`}
      />
    </Tooltip>
  );
}
