export const SHOW_PAYMENT_AGREEMENT = true;
export const SHOW_VENDOR_INTERCEPT = true;
export const TOOLTIP_TEXT = {
  "New!": "New Service!",
  "Free Preview":
    "Free Previews are provided for free for a limited time so you can explore our ever-growing portfolio of features. Free Previews are not available for all features.",
  "Coming Soon!": "Coming Soon",
  "Try it!": "Try it!",
  Beta: "Beta",
};
export const SOLICITATION_TAB_ORDER = [
  "incident_response",
  "data_mining",
  "document_review",
  "mailings",
  "emailings",
  "call_center",
  "credit_monitoring",
  "managed_detection_and_response",
  "betterment",
  "ediscovery_document_review",
  "class_action",
];

export const SERVICE_OPTIONS = [
  //! BE SURE TO ADD THE NEW SERVICE TO THE SOLICITATION_TAB_ORDER
  {
    id: 6,
    title: "Data Mining",
    description:
      "Services related to mining affected data to determine scope of breach.",
    value: "data_mining",
    category: ["Incident Response"],
  },
  {
    id: 7,
    title: "Document Review",
    description:
      "Services related to reviewing data to identify affected individuals.",
    value: "document_review",
    category: ["Incident Response"],
  },
  {
    id: 1,
    title: "Mailings",
    description: "Services related to mailing notices to affected individuals.",
    value: "mailings",
    category: ["Incident Response", "Class Action"],
  },
  {
    id: 2,
    title: "Emailings",
    description:
      "Services related to emailing notices to affected individuals.",
    value: "emailings",
    category: ["Incident Response", "Class Action"],
  },
  {
    id: 3,
    title: "Call Center",
    description:
      "Services related to call center support for affected individuals.",
    value: "call_center",
    category: ["Incident Response", "Class Action"],
  },

  {
    id: 5,
    title: "Credit Monitoring",
    description:
      "Services related to credit monitoring for affected individuals.",
    value: "credit_monitoring",
    category: ["Incident Response"],
  },
  {
    id: 8,
    title: "Incident Response",
    description: "Services related to incident response.",
    value: "incident_response",
    // status: "New Service!",
    category: ["Incident Response"],
  },

  // {
  //   id: 8,
  //   title: "Ransomware",
  //   description: "Services related to ransomware.",
  //   value: "ransomware",
  //   status: "New Service!",
  //   category: ["Incident Response"],
  //   // visible: true, // (profile) => profile && profile.tenantType === "admin",
  //   // enabled: true, // (profile) => profile && profile.tenantType === "admin",
  //   // enabled: false,
  // },
  // {
  //   id: 9,
  //   title: "Forensics",
  //   description: "Services related to forensic investigation.",
  //   value: "forensics",
  //   status: "New Service!",
  //   category: ["Incident Response"],
  //   // visible: () => true, // (profile) => profile && profile.tenantType === "admin",
  //   // enabled: () => true, // (profile) => profile && profile.tenantType === "admin",
  //   // enabled: false,
  //   // status: "Coming Soon",
  //   // enabled:
  //   //   profile && profile.tenantType && profile.tenantType === "admin"
  //   //     ? true
  //   //     : false,
  // },
  {
    id: 10,
    title: "Managed Detection and Response",
    description: "Services related to MDR.",
    value: "managed_detection_and_response",
    // enabled: false,
    // status: "New Service!",
    category: ["Incident Response", "Incident Preparedness"],
    // visible: () => true, // (profile) => profile && profile.tenantType === "admin",
    // enabled: () => true, // (profile) => profile && profile.tenantType === "admin",
    // status: "Coming Soon",
    // enabled:
    //   profile && profile.tenantType && profile.tenantType === "admin"
    //     ? true
    //     : false,
  },
  // {
  //   id: 11,
  //   title: "Betterment",
  //   description: "Betterment.",
  //   value: "betterment",
  //   // enabled: false,
  //   status: "New Service!",
  //   category: [
  //     "Incident Response",
  //     "Incident Preparedness",
  //     "Incident Recovery",
  //   ],
  //   visible: (profile) => {
  //     // return true;
  //     return (
  //       (profile && profile.tenantType && profile.tenantType === "admin") ||
  //       (profile &&
  //         profile.tenantId &&
  //         profile.tenantId === "64af0a0aace1891021c9e42a")
  //     );
  //   },
  // },
  {
    id: 12,
    title: "Document Review for eDiscovery",
    description:
      "Services related to processing and reviewing data for eDiscovery.",
    value: "ediscovery_document_review",
    category: ["eDiscovery"],
  },
  {
    id: 13,
    title: "Class Action",
    description: "Services related to class action administration.",
    value: "class_action",
    category: ["Class Action"],
    visible: (profile) => {
      return (
        profile &&
        (profile.tenantType === "admin" ||
          profile.tenantId === "64af0a0aace1891021c9e42a" ||
          profile.role === "Super Admin")
      );
    },
  },
];

export const PRICING_TYPES = [
  // {
  //   name: "Flat",
  //   value: "flat",
  //   description:
  //     "Choose this option if the user will pay one price for the entire order.",
  // },
  {
    name: "Standard",
    value: "standard",
    description:
      "Choose this option if the user will pay the same price for every unit.",
  },
  // {
  //   name: "Package",
  //   value: "package",
  //   description:
  //     "Select package pricing if you charge by the package, or group of units. For example, say you charge $25.00 for every 5 units. Purchases are rounded up by default, so a customer buying 8 units would pay $50.00.",
  // },
  {
    name: "Graduated",
    value: "graduated",
    description:
      "Select graduated pricing if you use pricing tiers that may result in a different price for some units in an order. For example, you might charge $10.00 per unit for the first 100 units and then $5.00 per unit for the next 50.",
  },
  {
    name: "Volume",
    value: "volume",
    description:
      "Select volume pricing if you charge the same price for each unit based on the total number of units sold. For example, you might charge $10.00 per unit for 50 units, and $7.00 per unit for 100 units.",
  },
];

export const BILLING_RECURRENCE = [
  {
    name: "One Time",
    title: "One Time",
    // description: "Charge the customer once for the entire order.",
  },
  {
    name: "Recurring",
    title: "Recurring",
    // description:
    // "Charge the customer on a recurring basis, such as every month or year.",
  },
];

export const BILLING_INTERVAL = [
  {
    name: "Day",
    description: "Charge the customer once for the entire order.",
  },
  {
    name: "Week",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
  {
    name: "Month",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
  {
    name: "Year",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
];

export const SOLICITATION_STATUS = ["Active", "Closed", "Archived", "Deleted"];

export const WORKFLOW_STATES = [
  "Accepting Bids",
  "Bidding Closed",
  "Winner Selected",
  "Winner Notified",
  "Closed",
  "Archived",
];
export const RESPONSE_STATUS = [
  "Draft",
  "Submitted",
  "Accepted",
  "Declined",
  "Withdrawn",
  "Archived",
];

export const RESPONSE_STATEES = ["Won", "Lost", "Declined", "Withdrawn"];

export const WORKFLOW_STATUS = [
  //response status
  "Draft",
  "Active",
  "Closed",
  "Archived",
  "Deleted",
];

export const SELECT_WINNER_OPTIONS = [
  {
    id: 1,
    title: "Lowest price",
    description: "The winner had the lowest price.",
    value: "lowest_price",
  },
  {
    id: 2,
    title: "Preferred price",
    description: "The winner offered preferred pricing.",
    value: "preferred_price",
  },
  {
    id: 3,
    title: "Prior relationship",
    description:
      "The winner had the prior relationship with the requestor or insurer.",
    value: "prior_relationship",
  },
  {
    id: 4,
    title: "Carrier preference",
    description: "The carrier had a preference for the winner.",
    value: "carrier_preference",
  },
  {
    id: 5,
    title: "Name recognition",
    description: "The winner was chosen based on name recognition.",
    value: "name_recognition ",
  },
  {
    id: 6,
    title: "Quote quality",
    description: "The winner's quote was easy to read and understand.",
    value: "quote_quality",
  },
  {
    id: 7,
    title: "Other",
    description:
      "The requestor is chose this winner for another reason. Please provide details.",
    value: "Other",
  },
];

export const CLOSE_REASON_OPTIONS = [
  {
    id: 1,
    title: "Prices too high",
    description: "All submitted prices were too high.",
    value: "prices_to_high",
  },
  {
    id: 2,
    title: "No longer needed",
    description: "The services are no longer needed.",
    value: "no_longer_needed",
  },
  {
    id: 3,
    title: "Used outside vendor",
    description:
      "The requestor or carrier chose to use a vendor that was not part of this bidding process.",
    value: "used_outside_vendor",
  },
  {
    id: 4,
    title: "Other",
    description:
      "The requestor is closing this bid for another reason. Please provide details.",
    value: "other",
  },
];
export const DECLINE_TO_BID_REASONS = [
  {
    id: 1,
    title: "Service not provided",
    description: "The vendor does not provide this service.",
    value: "service_not_provided",
  },
  {
    id: 2,
    title: "Too busy",
    description: "The vendor is too busy and is not taking on new projects.",
    value: "too_busy",
  },
  {
    id: 3,
    title: "Not enough resources",
    description:
      "The vendor provides the service but not on the scale of this request.",
    value: "not_enough_resources",
  },
  {
    id: 4,
    title: "Not a good fit",
    description:
      "The vendor provides the service but cannot meet the requirements of this request.",
    value: "not_a_good_fit",
  },
  {
    id: 5,
    title: "Other",
    description:
      "The vendor is declining for another reason. Please provide details.",
    value: "other",
  },
];
