import React, { useEffect, useState } from "react";
import {
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/20/solid";
import Button from "../elements/Button";
import { Icon } from "@iconify/react";
import Fade from "react-reveal/Fade";
export default function CopyComponent({
  text, //button text
  confirmText, //text to show after button is clicked
  handleClick, //function to call when button is clicked
}) {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    //after 5 seconds set copied to false
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [copied]);
  return (
    <div className="absolute -right-20 -top-2 ">
      <Button
        disabled={copied}
        color="text"
        handleClick={() => {
          handleClick();
          setCopied(!copied);
        }}
        type="button"
      >
        <Fade key={copied ? "copied" : "not-copied"}>
          {copied ? (
            <span className="flex items-center text-sm font-medium ">
              <Icon icon="mdi:check" className="h-5 w-5 mr-1 " />
              {confirmText && (
                <span className="text-sm font-medium ">{confirmText}</span>
              )}
            </span>
          ) : (
            <span className="flex items-center text-sm font-medium ">
              <Icon icon="ic:outline-content-copy" className="h-5 w-5 mr-1 " />
              {text && <span className="text-sm font-medium ">{text}</span>}
            </span>
          )}
        </Fade>
      </Button>{" "}
    </div>
  );
}
