import React, { useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import Button from "../elements/Button";
import { Link } from "react-router-dom";
function Empty(props) {
  const showButton = props.showButton ? props.showButton : false;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div
      id="empty"
      className="relative flex justify-center items-center min-h-full h-full"
    >
      {/* Content */}
      {/* <div className="min-h-full h-full justify-center content-center"> */}
      <div
        id="content"
        className="min-h-full h-full flex flex-col my-auto content-center justify-center"
      >
        <h2 className="flex items-center text-2xl text-slate-800 font-bold mb-2">
          <CheckCircleIcon
            fill="green"
            className="h-6 w-6 mr-2 flex items-center"
          />

          <div className="flex items-center">{props.title}</div>
        </h2>
        <div className="mb-6">{props.content}</div>
        <div className="flex">
          {props.showButton &&
            (props.buttonColor == "red" ? (
              <Link to={props.buttonLink}>
                <button className="btn bg-red-500 hover:bg-red-600 text-white">
                  <svg
                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="ml-2">{props.buttonText}</span>
                </button>
              </Link>
            ) : (
              <Link to={props.buttonLink}>
                <button className="btn bg-brand-500 hover:bg-brand-600 text-white">
                  {/* <svg
                                className="w-4 h-4 fill-current opacity-50 shrink-0"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                              </svg> */}
                  <span className="ml-2">{props.buttonText}</span>
                </button>
              </Link>
            ))}
        </div>
        <div className="flex">
          {props.showActionButton &&
            (props.buttonColor == "red" ? (
              <Button handleClick={props.handleAction} color="red">
                {props.actionButtonText}
              </Button>
            ) : (
              <Button handleClick={props.handleAction} color="green">
                {props.actionButtonText}
              </Button>
            ))}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Empty;
