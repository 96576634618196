import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import { Dropdown, RadioGroupSingle, Section, TextInput } from "./DataEntry";
import Tip from "../../../components/Tip";

export default function EmailingInfo({
  create,
  newProject,
  languageOptions,
  project,
  onFieldChange,
  handleMultiDeSelectNew,
  handleSelectedLanguage,
  handleDeselectedLanguage,
  handleClearAllSelections,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,
  scope,
  isChecked,
  handleSingleSelect,
  handleMultiDeSelect,
  handleMultiSelect,
}) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [opts2, setOpts2] = useState([]);

  useEffect(() => {
    setData(newProject);
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOptions(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOpts2(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
  }, [newProject]);

  return (
    <div className="mt-8">
      {/* {create && (
        <div>
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Options
          </h3>
          <Paragraph>
            Now, let's gather your specific requirements related to mailings.
          </Paragraph>
        </div>
      )} */}

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {create && create == true && (
          <div className=" col-span-full bg-[#f6f8ff] border border-brand-200 rounded p-2 pb-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <Section
              label="Assumptions"
              // description={`${
              //   create
              //     ? "These fields are required, and will help ensure a more consistent bidding process. "
              //     : "These are the assumptions that were made when the project was created. Please use these assumptions when creating your quotes."
              // }`}
              create={create}
            />
            <Tip colSpan={"full"} noMargin>
              <div className="inline">
                The fields in the assumptions section are required. It is
                extremely important to fill out the Assumptions section to get
                simple, accurate quotes.
                <span className="ml-1 font-medium">
                  We've provided you with some default answers.
                </span>
              </div>
            </Tip>
            <div id="assumption-list" className="col-span-full space-y-10">
              <TextInput
                label="Number of Affected Individuals"
                tooltip={{
                  title: "Affected Individuals",
                  text: "Letting the vendor know how many people were affected will help them determine how many letters will need to be mailed.",
                }}
                service={service}
                field="number_of_affected_individuals"
                // type="size"
                defaultValue={newProject.number_of_affected_individuals || null}
                create={create}
                opts2={opts2}
                placeholder=""
                onFieldChange={onFieldChange}
                required
              />
              <Dropdown
                label="Required Languages"
                subLabel="What languages do you require to be spoken by call center staff?"
                tooltip={{
                  title: "Call Center Languages",
                  text: "Staffing call centers with agents who speak multiple languages can be costly, so it is important to make this part of your request so that all vendors are consistent in their bidding..",
                }}
                service={service}
                field="required_language"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="multiSelect"
                open={true}
                defaultValue={["English"]}
                newProject={newProject}
                handleSelect={handleMultiSelect}
                handleDeselect={(value) =>
                  handleMultiDeSelect(value, service, "required_language")
                }
                handleDeselectedLanguage={(value) =>
                  handleMultiDeSelect(value, service, "required_language")
                }
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "required_language")
                }
                options={languageOptions ? languageOptions : []}
              />
              <RadioGroupSingle
                cols={3}
                rows={2}
                type="multiSelect"
                label="Project Reporting"
                description="Select your reporting requirements. We've already select the most common. "
                tooltip={{
                  title: "Project Reporting",
                  text: "Project reporting is a critical part of managing a call center.  It allows you to track performance and ensure that the vendor is meeting your expectations.",
                }}
                service={service}
                field="reporting_requirements"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single" //not sure if this is used
                open={true}
                newProject={newProject}
                handleSelect={handleMultiSelect}
                defaultValue={[
                  "project_confirmation",
                  "delivery_confirmation",
                  "open_confirmation",
                ]}
                options={[
                  {
                    title: "Project Confirmation",
                    value: "project_confirmation",
                  },
                  {
                    title: "Delivery Confirmation",
                    value: "delivery_confirmation",
                  },
                  {
                    title: "Open Confirmation",
                    value: "open_confirmation",
                  },
                ]}
              />
            </div>
          </div>
        )}

        <div className="sm:col-span-6 grid grid-cols-6">
          {create && (
            <div className="col-span-full mb-4">
              <h3 className="text-base font-semibold leading-6 text-slate-900">
                Additional Requirements
              </h3>
              <Paragraph>
                If you have other requirements you can specify them below.
              </Paragraph>
            </div>
          )}
          <Label sm htmlFor=" my-auto delay_mail" className="col-span-6 block ">
            Days required to email from receipt of final proofs
          </Label>
          {!create && (
            <div className="mt-1 col-span-full">
              <ReadOnlyAnswer
                answer={
                  opts2.delay_mail
                    ? opts2.delay_mail
                    : "No requirement provided."
                }
                type={"text"}
              />
            </div>
          )}
          {create && (
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="delay_mail"
                id="delay_mail"
                autoComplete="none"
                placeholder="0" //field, e, key, service
                value={opts2.delay_mail ? opts2.delay_mail : ""}
                onChange={(e) =>
                  onFieldChange("options", e, "delay_mail", "emailings")
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>

        <div className="sm:col-span-6">
          {/* <Paragraph>
            Check all the options below that apply to your needs.
          </Paragraph> */}
          <div className="mt-4 space-y-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="avoid_spam_filters"
                  name="avoid_spam_filters"
                  disabled={!create}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  placeholder="required_languages_checkbox"
                  value={opts2.avoid_spam_filters && opts2.avoid_spam_filters}
                  defaultChecked={
                    opts2.avoid_spam_filters && opts2.avoid_spam_filters
                  }
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "avoid_spam_filters",
                      "emailings"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <Label sm bold htmlFor="comments">
                  Circumvent Spam Filters
                </Label>
                <p className="text-slate-500">
                  Check this box if you would like the the provider to employ
                  techniques to circument spam filters.
                </p>
              </div>
            </div>
            {/* <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="return_mail"
                  name="return_mail"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  value={opts2.return_mail && opts2.return_mail}
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "return_mail",
                      "mailings"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="candidates"
                  className="font-medium text-slate-700"
                >
                  Return Mail
                </label>
                <p className="text-slate-500">
                  Check this box if you require the vendor to handle return
                  mail.
                </p>
              </div>
            </div> */}
          </div>
        </div>

        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          <Paragraph className="mt-2 text-sm text-slate-500">
            Use the below field to provide any additional details about your
            project or bid request that you think might be helpful to the
            vendor.
          </Paragraph>
          <div className="mt-1">
            {create ? (
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    "emailings",

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            ) : (
              <ReadOnlyAnswer
                type={"text"}
                answer={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : "No additional details provided."
                }
              />
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
