import { useEffect } from "react";
import Chips from "../../../components/Chips";
import { utils } from "../../../modules/_utils";
export default function ReadOnlyAnswer({ type, answer, chipColor }) {
  useEffect(() => {
    console.log("ReadOnlyAnswer.js: type: ", type);
    console.log("ReadOnlyAnswer.js: answer: ", answer);
    console.log("ReadOnlyAnswer.js: chipColor: ", chipColor);
  }, [type, answer]);

  const getAnswer = () => {
    switch (type) {
      case "text":
        return <p>{answer}</p>;
      case "chips":
        return <p>{<Chips values={answer} chipColor={chipColor} />}</p>;
      case "number":
        return <p>{answer}</p>;
      case "date":
        return <p>{answer}</p>;
      case "radio":
        return <p>{answer}</p>;
      case "checkbox":
        return <p>{answer}</p>;
      case "dropdown":
        return <p>{answer}</p>;
      case "file":
        return <p>{answer}</p>;
      case "rating":
        return <p>{answer}</p>;
      case "scale":
        return <p>{answer}</p>;
      case "grid":
        return <p>{answer}</p>;
      case "likert":
        return <p>{answer}</p>;
      case "date-time":
        return <p>{answer}</p>;
      case "time":
        return <p>{answer}</p>;
      case "image":
        return <p>{answer}</p>;
      case "video":
        return <p>{answer}</p>;
      case "audio":
        return <p>{answer}</p>;
      case "array":
        return <p>{<Chips values={answer} chipColor={chipColor} />}</p>;
      case "object":
        return <p>{answer}</p>;
      default:
        return <p>{answer}</p>;
    }
  };
  return <div className="col-span-full">{getAnswer()}</div>;
}
