import { Fragment, useEffect, useState } from "react";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { pricing_explanations } from "../../../modules/pricing_explanations";
import Badge from "../../../components/Badge";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";
import PDFViewerModal from "../../../components/PDFViewerModal";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { Solicitations } from "../../../modules/_solicitations";
import DropdownMenu from "../../../components/DropdownMenu";
import { Icon } from "@iconify/react";
import { VENDOR_NOTIFIED_ICON } from "../../../constants/icon_constants";
import Tooltip from "../../../components/Tooltip";
import BidCompareTableBodyV1 from "./BidCompareTableBodyV1";
import BidCompareTableBodyV2 from "./BidCompareTableBodyV2";

export default function BidCompareTable({
  responses,
  solicitation,
  handleSelectWinnerClick,
  handleNotifyVendorClick,
  handleSendBackForRevisionClick,
  handleViewPDF,
  legacy,
  showLineItems,
}) {
  const [allResponses, setAllResponses] = useState([]);
  // const [solicitation, setSolicitation] = useState(props.solicitation);
  const [allLineItems, setAllLineItems] = useState([]);
  const [allPricingItems, setAllPricingItems] = useState([]);
  const [colWidth, setColWidth] = useState("w-1/3");
  const [displayedPricingItems, setDisplayedPricingItems] = useState([]);
  const [workflowStatus, setWorkflowStatus] = useState("");
  const [winnerSelected, setWinnerSelected] = useState(false);
  const [winnerNotified, setWinnerNotified] = useState(false);

  useEffect(() => {
    console.log("LEGACY VERSION", legacy);
  }, [legacy]);
  useEffect(() => {
    console.log("Props.responses changed allResponses", responses);
    setAllResponses(responses);
    if (responses && responses.length > 0) {
      setColWidth(`w-1/4`);
    }
  }, [responses]);

  useEffect(() => {
    let allLineItems = [];
    let allPrices = [];
    let allServicePrices = [];
    // if (responses && responses.length > 0) {
    responses &&
      responses.length > 0 &&
      responses.map((response) => {
        console.log("BidCompareTable response", response);
        response?.services &&
          Object.entries(response.services)?.map(([k, v]) => {
            if (allLineItems && !allLineItems.includes(k)) {
              allLineItems.push(k);
            }
            console.log("BidCompareTable Services", k, v);
            v.pricing &&
              Object.entries(v.pricing) &&
              Object.entries(v.pricing).map(([key, v]) => {
                if (allPrices && !allPrices.includes(key)) {
                  allPrices.push(key);
                }
                //NOT WORKING YET
                if (allServicePrices && !allServicePrices[k]) {
                  //if allServicePrices is empty, add allServicePrices[v] first
                  allServicePrices[k] = [];
                }
                allServicePrices[k].push(key);
              });
          });
      });

    // }
    console.log("AllServicePrices", allServicePrices);
    setAllPricingItems(allServicePrices);
    setAllLineItems(allLineItems);
    console.log("allLineItems", allLineItems);
  }, [allResponses]);

  useEffect(() => {
    console.log("Checking if a winner has been selected", solicitation);
    let selected = "";
    let notified = "";
    if (solicitation && solicitation.workflow_status) {
      selected = Solicitations.findWorkflowStatus(
        "Winner Selected",
        solicitation.workflow_status
      )?.status;
      console.log("Checking if a winner has been selected", selected);
    }
    if (selected == "current" || selected == "complete") {
      setWinnerSelected(true);
    }
    if (notified == "current" || notified == "complete") {
      setWinnerNotified(true);
    }
  }, [responses, solicitation]);

  useEffect(() => {
    console.log(solicitation, "workflow");
    if (solicitation && solicitation.workflow_status) {
      setWorkflowStatus(
        Solicitations.getCurrentWorkflow(solicitation.workflow_status)?.name
      );
    }
  }, [solicitation?.workflow_status]);

  const generateRows = () => {
    // each row is a service
    // each column is a response
    // each cell is a pricing item (e.g. Base, Processing, Excel Review, PDF Review, Project Management)
    // each cell has a price, a unit, and a tier (if applicable)
    // each cell has a type (Standard or Volume)
    // each cell has a price_type (unit_pricing or flat)
    // each cell has a currency (USD)
    const rows = [];
    return <th scope="col">Service Name</th>;
  };

  return (
    // <table class="table-auto overflow-scroll col-span-full  	 text-sm text-left text-gray-500 ">
    <div
      id="bid-compare-table-scrollable-div"
      className="h-full w-full overflow-auto scrollbar-default 
      border border-gray-200 "
    >
      <table
        id="response_table"
        className="table-auto overflow-x-scroll overflow-y-scroll w-full text-sm text-left text-gray-500 "
      >
        {/* <div className="w-full overflow-x-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"> */}
        <thead class="sticky  z-10 top-0 w-full text-xs text-gray-700  bg-gray-50  ">
          <tr className="align-top">
            {/* the above tr: flex messes up the rest of the layout but in the way 
            I was hoping for */}
            <th
              id="column-headers-0"
              scope="col"
              class={`sticky left-0 h-fit  px-4 py-3  bg-gray-50  border-r border-gray-200`}
              style={{
                width: "200px",
                maxWidth: "200px",
                minWidth: "200px",
              }}
            ></th>
            {allResponses &&
              allResponses.length > 0 &&
              allResponses.map((response, idx) => {
                return (
                  <th
                    id={`column-headers-${idx + 1}`}
                    scope="col"
                    style={{
                      width: "300px",
                      maxWidth: "300px",
                      minWidth: "300px",

                      // Don't forget this, required for the stickiness
                    }}
                    class={`border-r max-w-96   whitespace-nowrap text-base pl-2 pr-6 pb-2 `}
                  >
                    <div className="pt-2 flex items-center justify-between space-y-0">
                      <div>
                        {" "}
                        {response.vendorCompanyId
                          ? response.vendorCompanyId.companyName
                          : response.submittedByCompany}
                        {/* {response.requestorDecision &&
                        response.requestorDecision == "winner" ? (
                          <div className="mr-2 mx-auto items-center">
                            <CheckIcon className="inline-flex mr-1.5 h-6 w-6 text-green-500" />{" "}
                            <div className="inline-flex">Winning bid</div>
                          </div>
                        ) : [
                            "Archived",
                            "Closed",
                            "Winner Selected",
                            "Winner Notified",
                          ].includes(workflowStatus) ? (
                          <div className="px-auto mx-auto items-center cursor-not-allowed">
                            <XMarkIcon className="inline-flex mr-1.5 h-6 w-6 text-red-500" />{" "}
                            <div className="inline-flex">Not selected</div>
                          </div>
                        ) : null} */}
                      </div>
                      <div className="flex items-center space-x-4 ">
                        <div>
                          {response.requestorDecision &&
                          response.requestorDecision == "winner" ? (
                            <div className=" mx-auto items-center">
                              <Tooltip
                                // className="cursor-default"
                                // title="Winner"
                                description="This is the winning bid."
                              >
                                <Icon
                                  icon="mdi:trophy"
                                  className="cursor-default text-emerald-600"
                                />
                              </Tooltip>
                            </div>
                          ) : [
                              "Archived",
                              "Closed",
                              "Winner Selected",
                              "Winner Notified",
                            ].includes(workflowStatus) ? (
                            <Tooltip
                              // title="Winner"
                              description="This bid did not win."
                            >
                              <div className="px-auto mx-auto items-center">
                                <XMarkIcon className="cursor-default inline-flex mr-1.5 h-6 w-6 text-red-500" />{" "}
                                <div className="inline-flex"></div>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </div>
                        {response.vendorNotified &&
                          response.vendorNotified == true && (
                            <div>
                              <div className="items-center">
                                <Tooltip description="The winning bidder has been notified.">
                                  {" "}
                                  <Icon
                                    icon="mdi:envelope"
                                    className="cursor-default text-emerald-600"
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        {response.isReturnedToVendor &&
                          response.isReturnedToVendor == true && (
                            <div>
                              <div className="items-center">
                                <Tooltip description="Pending vendor revision.">
                                  {" "}
                                  <Icon
                                    icon="fluent:archive-arrow-back-24-filled"
                                    className="cursor-default text-red-600"
                                  />
                                  {/* <Icon
                                    icon="mdi:envelope"
                                    className="cursor-default text-emerald-600"
                                  /> */}
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        <div className="font-normal">
                          <DropdownMenu
                            id="menu"
                            showChevron={false}
                            buttonType="icon" //could be text
                            buttonText="Actions"
                            buttonColor="transparent"
                            menuItems={[
                              {
                                name: "Ask a question",
                                icon: "ri:questionnaire-line",

                                action: (e) => {
                                  console.log("Ask a question");
                                },
                                disabled: false,
                                status: "Beta",
                                show: false,
                                group: "",
                              },
                              {
                                name: "Ask for revision",
                                // icon: "mdi:library-remove-outline",
                                icon: "bi:send-slash-fill",

                                action: (e) => {
                                  console.log("Send back for revision");
                                  handleSendBackForRevisionClick(response);
                                },
                                disabled: false,
                                status: "Beta",
                                show:
                                  response.requestorAction &&
                                  response.requestorAction === "pending" &&
                                  response.requestorDecision &&
                                  response.requestorDecision === "pending",
                                // show:
                                //   !response.removedFromConsideration &&
                                //   response.requestorAction !==
                                //     "winner selected",

                                group: "",
                              },

                              {
                                name: !response.isShortlisted
                                  ? "Add to shortlist"
                                  : "Remove from shortlist",
                                icon: !response.isShortlisted
                                  ? "icon-park-outline:list-add"
                                  : "fluent:channel-arrow-left-20-regular",

                                action: (e) => {
                                  console.log("Shortlist button clicked");
                                },
                                show: false,
                                // show:
                                //   response.requestorAction !==
                                //   "winner selected",
                                group: "",
                                status: "Beta",
                              },
                              {
                                name: "Select Winner",
                                icon: "mdi:trophy",
                                action: (e) => {
                                  !response.requestorDecision !== "winner" &&
                                    handleSelectWinnerClick(e, response);
                                },
                                show:
                                  response.requestorAction &&
                                  response.requestorAction === "pending" &&
                                  response.requestorDecision &&
                                  response.requestorDecision === "pending",
                                group: "Select",
                              },
                              {
                                name: "Undo Select Winner",
                                icon: "mdi:undo-variant",

                                action: (e) => {
                                  !response.requestorDecision !== "winner" &&
                                    handleSelectWinnerClick(e, response);
                                },
                                show: false,
                                // show:
                                //   response.isWinner && !response.vendorNotified,
                                group: "Select",
                              },
                              {
                                name: "Notify Winner",
                                icon: "mdi:envelope",
                                action: (e) => {
                                  console.log("Notify Winner");
                                  handleNotifyVendorClick(e, response);
                                },
                                show:
                                  response.isWinner && !response.vendorNotified,
                                group: "Select",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="block mt-0 text-sm font-normal text-gray-600">
                        ID:{" "}
                        {response.GlobalId ? response.GlobalId : response._id}
                      </div>
                      <div className="mt-0 text-sm font-normal text-gray-600">
                        Submitted on{" "}
                        {utils.formatDate(response.submittedOn, "short")}
                      </div>
                      {/* APP-364 */}
                      {/* <p className=" text-sm leading-6 text-gray-600">
                        by{" "}
                        <span className="font-semibold">
                          {response.submittedByEmail}
                        </span>
                      </p> */}

                      <div className="font-normal">
                        <div>
                          {response.attachments &&
                          response.attachments.length > 0 ? (
                            <ul
                              role="list"
                              className="divide-y divide-gray-200 rounded-md border border-gray-200"
                            >
                              {response.attachments &&
                                response.attachments.map((attachment) => (
                                  <li
                                    key={attachment.name}
                                    onClick={(e) => {
                                      handleViewPDF(e, attachment);
                                      // console.log("Downloading attachment", attachment);
                                      // handleSetPdfModalOpen(attachment, "response");
                                    }}
                                    className="bg-white flex cursor-pointer items-center justify-between py-3 pl-3 pr-4 text-sm"
                                  >
                                    <div className="flex w-0 flex-1 items-center">
                                      <PaperClipIcon
                                        className="h-5 w-5 flex-shrink-0 text-slate-400"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-2 w-0 flex-1 truncate">
                                        {attachment.name}
                                      </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                      <a
                                        // href={attachment.href}
                                        onClick={(e) => {
                                          handleViewPDF(e, attachment);
                                          // handleSetPdfModalOpen(attachment, "response");
                                        }}
                                        className="font-medium text-brand-600 hover:text-brand-500"
                                      >
                                        View
                                      </a>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </th>
                );
              })}
          </tr>
        </thead>
        {legacy ? (
          <BidCompareTableBodyV1
            allResponses={allResponses}
            allLineItems={allLineItems}
            allPricingItems={allPricingItems}
            responses={responses}
          />
        ) : (
          <BidCompareTableBodyV2
            showLineItems={showLineItems}
            allResponses={allResponses}
            allLineItems={allLineItems}
            allPricingItems={allPricingItems}
            responses={responses}
          />
        )}
        {/* </div> */}
      </table>
    </div>
  );
}
