import React from "react";
import "../css/chat-bubble.css";

const ChatBubble = ({
  isSender,
  senderName,
  backgroundColor,
  textColor,
  children,
}) => {
  const defaultBgColor = isSender ? "#3b82f6" : "#7480ff"; // Default bubble color
  const defaultTextColor = isSender ? "#fff" : "#fff"; // Default text color

  return (
    <div className={`chat ${isSender ? "chat-end" : "chat-start"}`}>
      <div
        className="chat-bubble"
        style={{
          backgroundColor: backgroundColor || defaultBgColor,
          color: textColor || defaultTextColor,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ChatBubble;
