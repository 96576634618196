import { useEffect } from "react";
import { useState } from "react";
import Chip from "../elements/Chip";

export default function Chips(props) {
  const [values, setValues] = useState(props.values || []);
  useEffect(() => {
    setValues(props.values);
    console.log("Chips.js: values: ", values);
  }, [values]);
  return (
    <dd className="mt-1 text-sm text-slate-900">
      {values &&
        //check if value  is an array
        Array.isArray(values) &&
        values.map((value, index) => (
          <Chip
            value={value}
            index={index}
            chipColor={props.chipColor || null}
          />
        ))}
      {values && !Array.isArray(values) && (
        <Chip value={values} index={0} chipColor={props.chipColor || null} />
      )}
    </dd>
  );
}
