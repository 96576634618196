import React, { useEffect, useState } from "react";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import { Label } from "../../../components/Typography/FieldLabel";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { Paragraph } from "../../../components/Typography/Paragraph";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { utils } from "../../../modules/_utils";
import { get } from "immutable";
const getOpts2 = (group, newProject, service, field) => {
  console.log(
    "getOpts2",
    "group:",
    group,
    "newProject:",
    newProject,
    "service",
    service,
    "field",
    field
  );
  if (!newProject) return undefined;
  if (!newProject.services) return undefined;

  if (group) {
    return newProject.services[
      newProject.services.findIndex((el) => el.service === service)
    ].opts2[group];
  } else {
    return newProject.services[
      newProject.services.findIndex((el) => el.service === service)
    ].opts2;
  }
};
export const TextInput = (props) => {
  const {
    label,
    service,
    field,
    create,
    opts2,
    placeholder,
    tooltip,
    onFieldChange,
    type,
    indicator,
    disabled,
    group,
    important,
    prefix,
  } = props;
  // let opts2 = group ? props.opts2[group] : props.opts2;
  const subLabel = props.subLabel ? props.subLabel : null;
  const defaultValue = props.defaultValue ? props.defaultValue : null;
  const required = props.required ? props.required : false;
  console.log("TextInput props", field, props);
  const [err, setErr] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const [newOpts2, setNewOpts2] = useState();
  const [overrideDefaultValue, setOverrideDefaultValue] = useState(false);

  useEffect(() => {
    if (!opts2 || Object.keys(opts2).length === 0) return;

    let value = "";
    //if opts2 is {}, return
    if (!opts2 || Object.keys(opts2).length === 0) {
      return;
    }
    if (opts2[field]) {
      console.log("opts2[field]", field, "has value", opts2[field]);
      value = opts2[field];
    } else if (overrideDefaultValue) {
      console.log("opts2[field]", field, "has value", opts2[field]);
      value = opts2[field] || null;
    } else if (defaultValue && defaultValue !== null && create) {
      console.log(
        "opts2[field]",
        field,
        "does not have value, using defaultValue",
        field,
        defaultValue
      );
      value = defaultValue;
      if (service === "credit_monitoring") {
        onFieldChange(
          "options",
          {
            target: { name: field, value: defaultValue },
          },
          field,
          service,
          true
        );
      } else {
        onFieldChange(
          "options",
          {
            target: { name: field, value: defaultValue },
          },
          field,
          service
        );
      }
    } else {
      console.log("opts2[field]", field, "no values", field);
    }
    setDisplayValue(value);
  }, [opts2]);

  useEffect(() => {
    if (
      required &&
      (displayValue == "" || displayValue == null || !displayValue)
    ) {
      setErr(true);
    } else {
      setErr(false);
    }
  }, [displayValue]);

  const handleInputChange = (e) => {
    setIsChanged(true);
    setOverrideDefaultValue(true);
    const value = e.target.value;
    setDisplayValue(value);
    onFieldChange("options", e, field, service);
  };

  const addOverride = (usingDefault) => {
    if (usingDefault) return;
    // e.preventDefault();
    console.log("addOverride", field, group);
    if (checkOverride) {
      return;
    }

    setOverrideDefaultValue(true);
  };
  // const removeAllOverridesForGroup = (group) => {
  //   console.log("removeAllOverridesForGroup", group);
  //   let newOverrides = [...overrides];
  //   newOverrides = newOverrides.filter((override) => override.group !== group);
  //   setOverrides(newOverrides);
  // };

  const removeOverride = () => {
    setOverrideDefaultValue(false);
  };

  const checkOverride = () => {
    console.log("chkOverride");
    let chk = false;
    chk = overrideDefaultValue;

    return chk;
  };

  return (
    <div className="sm:col-span-6 flex flex-col ">
      <div className="flex flex-col col-span-full lg:col-span-3">
        <Label
          bold
          sm
          htmlFor={field}
          className="col-span-4 block text-slate-700 items-center"
        >
          {label}{" "}
          {create && tooltip && tooltip.text && (
            <QuestionToolTip
              color="brand"
              text={tooltip.text}
              field={tooltip.title}
              size={tooltip.size || "md"}
              html={tooltip.html || false}
            />
          )}
        </Label>
        {subLabel && (
          <p
            htmlFor={field}
            sm
            className="text-sm text-gray-600 my-1 col-span-4"
          >
            {subLabel}
          </p>
        )}
        {!create && (
          <div className="col-span-6 mb-6">
            <ReadOnlyAnswer
              answer={opts2[field] ? opts2[field] : ""}
              type="text"
            />
          </div>
        )}{" "}
      </div>
      <div className="grid grid-cols-6">
        {create && (
          <div className=" mt-1 col-span-full lg:col-span-2 flex rounded-md shadow-sm relative">
            {type && type == "size" && (
              <div className=" items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3">
                GB
              </div>
            )}

            {type && type == "hours_per_week" && (
              <div className=" items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3">
                hrs/wk
              </div>
            )}
            {indicator && (
              <div
                className={`items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3 ${
                  disabled ? "text-gray-500" : ""
                }`}
              >
                {indicator}
              </div>
            )}
            {prefix && (
              <div className="pb-1  items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
                {prefix}
              </div>
            )}

            <input
              name={field}
              type="text"
              id={field}
              autoComplete="none"
              placeholder={placeholder} //field, e, key, service
              // value={opts2[field] ? opts2[field] : defaultValue}
              disabled={disabled}
              onChange={(e) => handleInputChange(e)}
              value={displayValue}
              // onChange={handleInputChange}

              className={`block w-full min-w-0 flex-1  rounded-md border-gray-300 

                ${prefix && " pl-8 pr-40 "}
                
                ${
                  required && err
                    ? "border-l-4 border-l-red-500"
                    : important
                    ? "border-l-4 border-l-brand-500"
                    : ""
                } focus:border-brand-500 focus:ring-brand-500 sm:text-sm ${
                disabled ? "bg-gray-100 text-gray-500" : ""
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export const TextInputNewSolicitation = (props) => {
  const {
    label,
    service,
    field,
    create,
    opts2,
    placeholder,
    tooltip,
    onFieldChange,
    type,
    indicator,
    disabled,
    group,
  } = props;
  // let opts2 = group ? props.opts2[group] : props.opts2;
  const subLabel = props.subLabel ? props.subLabel : null;
  const defaultValue = props.defaultValue ? props.defaultValue : null;
  const required = props.required ? props.required : false;
  console.log("TextInput props", field, props);
  const [err, setErr] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const [newOpts2, setNewOpts2] = useState();

  // useEffect(() => {
  //   if (group) {
  //     console.log("#### Opts2 in TextInput #### found", field, group);
  //     console.log("#### Opts2 in TextInput ####", field, opts2[group]);
  //   } else {
  //     console.log("#### Opts2 in TextInput #### not found", field, group);
  //     console.log("#### Opts2 in TextInput ####", field, opts2);
  //   }
  // }, [opts2]);

  useEffect(() => {
    if (!opts2 || Object.keys(opts2).length === 0) return;

    let value = "";
    //if opts2 is {}, return
    if (!opts2 || Object.keys(opts2).length === 0) {
      return;
    }

    if (opts2[field]) {
      console.log("opts2[field]", field, "has value", opts2[field]);
      value = opts2[field];
    } else if (defaultValue && defaultValue !== null && create) {
      console.log(
        "opts2[field]",
        field,
        "does not have value, using defaultValue",
        field,
        defaultValue
      );
      value = defaultValue;
      onFieldChange(
        "options",
        {
          target: { name: field, value: defaultValue },
        },
        field,
        service
      );
    } else {
      console.log("opts2[field]", field, "no values", field);
    }
    setDisplayValue(value);
  }, [opts2]);

  useEffect(() => {
    if (
      required &&
      (displayValue == "" || displayValue == null || !displayValue)
    ) {
      setErr(true);
    } else {
      setErr(false);
    }
  }, [displayValue]);

  const handleInputChange = (e) => {
    setIsChanged(true);
    const value = e.target.value;
    setDisplayValue(value);
    onFieldChange("options", e, field, service);
  };

  return (
    <div className="sm:col-span-6 flex flex-col ">
      <div className="flex flex-col col-span-full lg:col-span-3">
        <Label
          bold
          sm
          htmlFor={field}
          className="col-span-4 block text-slate-700 items-center"
        >
          {label}{" "}
          {create && tooltip && tooltip.text && (
            <QuestionToolTip text={tooltip.text} field={tooltip.title} />
          )}
        </Label>
        {subLabel && (
          <p
            htmlFor={field}
            sm
            className="text-sm text-gray-600 my-1 col-span-4"
          >
            {subLabel}
          </p>
        )}
        {!create && (
          <div className="col-span-6 mb-6">
            <ReadOnlyAnswer
              answer={opts2[field] ? opts2[field] : ""}
              type="text"
            />
          </div>
        )}{" "}
      </div>
      <div className="grid grid-cols-6">
        {create && (
          <div className=" mt-1 col-span-full lg:col-span-2 flex rounded-md shadow-sm relative">
            {type && type == "size" && (
              <div className=" items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3">
                GB
              </div>
            )}
            {type && type == "hours_per_week" && (
              <div className=" items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3">
                hrs/wk
              </div>
            )}
            {indicator && (
              <div
                className={`items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3 ${
                  disabled ? "text-gray-500" : ""
                }`}
              >
                {indicator}
              </div>
            )}

            <input
              name={field}
              type="text"
              id={field}
              autoComplete="none"
              placeholder={placeholder} //field, e, key, service
              // value={opts2[field] ? opts2[field] : defaultValue}
              disabled={disabled}
              onChange={(e) => handleInputChange(e)}
              value={displayValue}
              // onChange={handleInputChange}
              className={`block w-full min-w-0 flex-1  rounded-md border-gray-300 ${
                required && err ? "border-l-4 border-l-red-500" : ""
              } focus:border-brand-500 focus:ring-brand-500 sm:text-sm ${
                disabled ? "bg-gray-100 text-gray-500" : ""
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export const Dropdown = (props) => {
  const {
    label,
    subLabel,
    service,
    field,
    create,
    options,
    fieldType,
    handleClearAllSelections,
    handleSelect,
    handleDeselect,
    handleDeselectedLanguage,
    opts2,
    placeholder,
    tooltip,
    onFieldChange,
    newProject,
  } = props;
  const defaultValue = props.defaultValue ? props.defaultValue : null;
  const [data, setData] = React.useState(null);

  useEffect(() => {
    console.log("newProject in DataEntry", newProject);
    setData(newProject);
  }, [newProject]);

  console.log("OPTS2 Dropdown", field, opts2);
  useEffect(() => {
    console.log("RadioGroupSingle", field, props.using);
    let opts2 =
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2;
    if (props.group) {
      opts2 = opts2[props.group];
    }

    // Log each scenario
    if (fieldType === "multiSelect") {
      console.log(field, "fieldType is multiSelect");
    }
    if (fieldType === "single") {
      console.log(field, "fieldType is single");
    }
    if (!opts2) {
      console.log(field, "opts2 doesn't exist");
      return;
    }
    if (!opts2[field]) {
      console.log(field, "opts2[field] doesn't exist");
    }
    if (opts2[field] === null) {
      console.log(field, "opts2[field] is null");
    }
    if (opts2[field] === "") {
      console.log(field, "opts2[field] is an empty string");
    }
    if (Array.isArray(opts2[field])) {
      console.log(field, "opts2[field] is an array");
    }
    if (
      opts2[field] &&
      Array.isArray(opts2[field]) &&
      opts2[field].length === 0
    ) {
      console.log(field, "opts2[field] is an empty array");
    }

    if (!defaultValue || defaultValue === null) return;
    if (!opts2) return;

    let useDefaultValue = false;
    let newDefaultValue = null;

    // If fieldType is multiSelect, a default value exists, and opts2[field] doesn't exist or is an empty array, set the default value
    // If the fieldType is singleSelect, a default value exists, opts2[field] is null, is an empty array, or is an empty string, set the default value
    if (
      (fieldType === "multiSelect" &&
        defaultValue &&
        (!opts2[field] ||
          opts2[field].length === 0 ||
          opts2[field] === null)) ||
      ((fieldType === "single" || !fieldType) &&
        defaultValue &&
        (!opts2[field] ||
          opts2[field] === null ||
          opts2[field] === "" ||
          Array.isArray(opts2[field])))
    ) {
      console.log(field, "useDefaultValue is true");
      useDefaultValue = true;
    }

    if (useDefaultValue) {
      if (fieldType === "multiSelect") {
        newDefaultValue = Array.isArray(defaultValue)
          ? defaultValue
          : [defaultValue];
        newDefaultValue.forEach((value) => {
          handleSelect(value, service, field);
        });
      } else {
        newDefaultValue = defaultValue;
        handleSelect(newDefaultValue, service, field);
      }
      console.log(field, "newDefaultValue", newDefaultValue);
    }
  }, [defaultValue, fieldType, opts2, field, service, handleSelect]);
  // useEffect(() => {
  //   // Log each scenario
  //   if (fieldType === "multiSelect") {
  //     console.log(field, "fieldType is multiSelect");
  //   }
  //   if (fieldType === "single") {
  //     console.log(field, "fieldType is single");
  //   }
  //   if (!opts2[field]) {
  //     console.log(field, "opts2[field] doesn't exist");
  //   }
  //   if (opts2[field] === null) {
  //     console.log(field, "opts2[field] is null");
  //   }
  //   if (opts2[field] === "") {
  //     console.log(field, "opts2[field] is an empty string");
  //   }
  //   if (Array.isArray(opts2[field])) {
  //     console.log(field, "opts2[field] is an array");
  //   }
  //   if (
  //     opts2[field] &&
  //     Array.isArray(opts2[field]) &&
  //     opts2[field].length === 0
  //   ) {
  //     console.log(field, "opts2[field] is an empty array");
  //   }

  //   if (!defaultValue || defaultValue === null) return;
  //   if (!opts2) return;

  //   let useDefaultValue = false;
  //   let newDefaultValue = null;

  //   // If fieldType is multiSelect, a default value exists, and opts2[field] doesn't exist or is an empty array, set the default value
  //   // If the fieldType is singleSelect, a default value exists, opts2[field] is null, is an empty array, or is an empty string, set the default value
  //   if (
  //     (fieldType === "multiSelect" &&
  //       defaultValue &&
  //       (!opts2[field] ||
  //         opts2[field].length === 0 ||
  //         opts2[field] === null)) ||
  //     ((fieldType === "single" || !fieldType) &&
  //       defaultValue &&
  //       (!opts2[field] ||
  //         opts2[field] === null ||
  //         opts2[field] === "" ||
  //         Array.isArray(opts2[field])))
  //   ) {
  //     console.log(field, "useDefaultValue is true");
  //     useDefaultValue = true;
  //   }

  //   if (useDefaultValue) {
  //     if (fieldType === "multiSelect") {
  //       newDefaultValue = Array.isArray(defaultValue)
  //         ? defaultValue
  //         : [defaultValue];
  //       newDefaultValue.forEach((value) => {
  //         handleSelect(value, service, field);
  //       });
  //     } else {
  //       newDefaultValue = defaultValue;
  //       handleSelect(newDefaultValue, service, field);
  //     }
  //     console.log(field, "newDefaultValue", newDefaultValue);
  //   }
  // }, [defaultValue, fieldType, opts2, field, service, handleSelect]);

  // const getValue = () => {
  //   if (!opts2) return;
  //   if (!props.defaultValue) return opts2[field] ? opts2[field] : "";
  //   console.log("getValue");

  //   if (fieldType === "multiSelect") {
  //     if (opts2 && Array.isArray(opts2[field]) && opts2[field].length > 0) {
  //       return opts2[field];
  //     } else if (defaultValue) {
  //       return defaultValue;
  //     } else {
  //       return [];
  //     }
  //   } else {
  //     console.log("getValue singleSelect");
  //     if (
  //       opts2 &&
  //       opts2[field] !== null &&
  //       opts2[field] !== undefined &&
  //       opts2[field] !== "" &&
  //       !Array.isArray(opts2[field])
  //     ) {
  //       console.log("getValue opts2[field] is not null", opts2[field]);
  //       return opts2[field];
  //     } else if (props.defaultValue) {
  //       console.log("getValue defaultValue", defaultValue);
  //       return props.defaultValue;
  //     } else {
  //       console.log("getValue returning empty string");
  //       return "";
  //     }
  //   }
  // };
  return (
    <div className="sm:col-span-6 grid grid-cols-6">
      <div className="flex flex-col col-span-full lg:col-span-3">
        <Label sm bold htmlFor={field} className="my-auto  block col-span-4">
          {label}
          {create && tooltip && tooltip.text && (
            <QuestionToolTip
              text={tooltip.text}
              field={tooltip.title}
              size={tooltip.size || "md"}
              html={tooltip.html || false}
            />
          )}
        </Label>
        {subLabel && create && (
          <p
            htmlFor={field}
            sm
            className="text-sm text-gray-600 my-1 col-span-4"
          >
            {subLabel}
          </p>
        )}
        {!create && (
          <div className="col-span-full mb-6">
            <ReadOnlyAnswer
              type={"chips"}
              answer={
                newProject &&
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2[field]
              }
            />
          </div>
        )}

        {create && (
          <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
            <ComboboxSelector
              name={field}
              options={options}
              fieldType={fieldType}
              open={true}
              handleSelect={(v) => handleSelect(v, service, field)}
              handleDeselect={handleDeselect}
              handleClearAllSelections={(value) =>
                handleClearAllSelections(value, service, field)
              } //{handleClearAllSelections}
              // value={opts2[field] ? opts2[field] : null}
              // value={getValue}
              // value={() => {
              //   return fieldType === "multiSelect"
              //     ? newProject &&
              //       newProject.services &&
              //       newProject.services[
              //         newProject.services.findIndex(
              //           (el) => el.service === service
              //         )
              //       ]?.opts2[field]
              //       ? newProject.services[
              //           newProject.services.findIndex(
              //             (el) => el.service === service
              //           )
              //         ].opts2[field]
              //       : defaultValue || [] // Fallback to defaultValue or empty array
              //     : newProject &&
              //       newProject.services &&
              //       newProject.services[
              //         newProject.services.findIndex(
              //           (el) => el.service === service
              //         )
              //       ]?.opts2[field] !== undefined &&
              //       newProject.services[
              //         newProject.services.findIndex(
              //           (el) => el.service === service
              //         )
              //       ]?.opts2[field] !== null &&
              //       newProject.services[
              //         newProject.services.findIndex(
              //           (el) => el.service === service
              //         )
              //       ]?.opts2[field] !== ""
              //     ? newProject.services[
              //         newProject.services.findIndex(
              //           (el) => el.service === service
              //         )
              //       ].opts2[field]
              //     : defaultValue || ""; // Fallback to defaultValue or empty string
              // }}
              value={() => {
                console.log("ComboboxSelector fieldType", field, fieldType);
                if (fieldType === "multiSelect") {
                  if (
                    opts2 &&
                    Array.isArray(opts2[field]) &&
                    opts2[field].length > 0
                  ) {
                    console.log(
                      "ComboboxSelector using opts value",
                      field,
                      opts2[field]
                    );
                    return opts2[field];
                  } else if (defaultValue) {
                    console.log(
                      "ComboboxSelector using defaultValue",
                      field,
                      defaultValue
                    );
                    return defaultValue;
                  } else {
                    console.log("ComboboxSelector using empty array", field);
                    return [];
                  }
                } else {
                  if (
                    opts2 &&
                    opts2[field] !== null &&
                    opts2[field] !== undefined &&
                    opts2[field] !== "" &&
                    !Array.isArray(opts2[field])
                  ) {
                    console.log(
                      "ComboboxSelector using opts value",
                      field,
                      opts2[field]
                    );
                    return opts2[field];
                  } else if (defaultValue) {
                    console.log(
                      "ComboboxSelector using defaultValue",
                      field,
                      defaultValue
                    );
                    return defaultValue;
                  } else {
                    console.log("ComboboxSelector using empty string", field);
                    return "";
                  }
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const RadioGroupSingle = (props) => {
  const {
    label,
    subLabel,
    description,
    service,
    field,
    create,
    options,
    cols,
    type,
    handleClearAllSelections,
    handleSelect,
    handleDeselectedLanguage,
    placeholder,
    tooltip,
    onFieldChange,
    newProject,
    group,
    opts2,
  } = props;
  const stacked = props.stacked ? props.stacked : false;
  const defaultValue = props.defaultValue ? props.defaultValue : null;
  const getGridColsClass = (cols) => {
    switch (cols) {
      case 1:
        return "2xl:grid-cols-1";
      case 2:
        return "2xl:grid-cols-2";
      case 3:
        return "2xl:grid-cols-3";
      case 4:
        return "2xl:grid-cols-4";
      case 5:
        return "2xl:grid-cols-5";
      case 6:
        return "2xl:grid-cols-6";
      case 7:
        return "2xl:grid-cols-7";
      case 8:
        return "2xl:grid-cols-8";
      case 9:
        return "2xl:grid-cols-9";
      case 10:
        return "2xl:grid-cols-10";
      case 11:
        return "2xl:grid-cols-11";
      case 12:
        return "2xl:grid-cols-12";
      default:
        return "2xl:grid-cols-1";
    }
  };
  // const [opts2, setOpts2] = useState(null);

  // useEffect(() => {
  //   let o = null;
  //   if (group) {
  //     o =
  //       newProject.services[
  //         newProject.services.findIndex((el) => el.service === service)
  //       ].opts2[group];
  //   } else {
  //     o =
  //       newProject.services[
  //         newProject.services.findIndex((el) => el.service === service)
  //       ].opts2;
  //   }
  //   setOpts2(newProject);
  // }, [newProject]);

  // useEffect(() => {
  //   if (defaultValue && create) {
  //     handleSelect(defaultValue, service, field);
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    console.log("RadioGroupSingle", field, props.using);
    let opts2 =
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2;
    if (props.group) {
      opts2 = opts2[props.group];
    }
    let fieldType = type;
    // Log each scenario
    if (fieldType === "multiSelect") {
      console.log(field, "fieldType is multiSelect");
    }
    if (fieldType === "single") {
      console.log(field, "fieldType is single");
    }
    if (!opts2) {
      console.log(field, "opts2 doesn't exist");
      return;
    }
    if (!opts2[field]) {
      console.log(field, "opts2[field] doesn't exist");
    }
    if (opts2[field] === null) {
      console.log(field, "opts2[field] is null");
    }
    if (opts2[field] === "") {
      console.log(field, "opts2[field] is an empty string");
    }
    if (Array.isArray(opts2[field])) {
      console.log(field, "opts2[field] is an array");
    }
    if (
      opts2[field] &&
      Array.isArray(opts2[field]) &&
      opts2[field].length === 0
    ) {
      console.log(field, "opts2[field] is an empty array");
    }

    if (!defaultValue || defaultValue === null) return;
    if (!opts2) return;

    let useDefaultValue = false;
    let newDefaultValue = null;

    // If fieldType is multiSelect, a default value exists, and opts2[field] doesn't exist or is an empty array, set the default value
    // If the fieldType is singleSelect, a default value exists, opts2[field] is null, is an empty array, or is an empty string, set the default value
    if (
      (fieldType === "multiSelect" &&
        defaultValue &&
        (!opts2[field] ||
          opts2[field].length === 0 ||
          opts2[field] === null)) ||
      ((fieldType === "single" || !fieldType) &&
        defaultValue &&
        (!opts2[field] ||
          opts2[field] === null ||
          opts2[field] === "" ||
          Array.isArray(opts2[field])))
    ) {
      console.log(field, "useDefaultValue is true");
      useDefaultValue = true;
    }

    if (useDefaultValue) {
      if (fieldType === "multiSelect") {
        newDefaultValue = Array.isArray(defaultValue)
          ? defaultValue
          : [defaultValue];
        newDefaultValue.forEach((value) => {
          handleSelect(value, service, field);
        });
      } else {
        newDefaultValue = defaultValue;
        handleSelect(newDefaultValue, service, field);
      }
      console.log(field, "newDefaultValue", newDefaultValue);
    }
  }, [defaultValue, type, opts2, field, service, handleSelect]);

  useEffect(() => {
    console.log("RadioGroupSingle OPTS2", opts2);
  }, [opts2]);

  return (
    <div className="col-span-full ">
      <div className="col-span-full">
        <Label sm bold htmlFor={field} className="block  ">
          {label}
          {create && tooltip && tooltip.text && (
            <QuestionToolTip
              text={tooltip.text}
              field={tooltip.title}
              size={tooltip.size || "md"}
              html={tooltip.html || false}
            />
          )}
        </Label>
        {subLabel && (
          <p htmlFor={field} sm className="text-sm text-gray-600 mt-1 ">
            {/* className="text-sm text-gray-600 my-1 col-span-4" */}
            {subLabel}
          </p>
        )}
        {create && description && (
          <p className="mt-2 text-sm text-slate-600">{description}</p>
        )}
        <RadioGroupCards
          name="services"
          type={type}
          stacked={stacked}
          defaultValue={() => {
            if (group) {
              return newProject &&
                newProject.services &&
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2?.[group]?.[field]
                ? newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2?.[group]?.[field]
                : defaultValue
                ? defaultValue
                : "";
            } else {
              return newProject &&
                newProject.services &&
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2?.[field]
                ? opts2[field]
                : defaultValue
                ? defaultValue
                : "";
            }
          }}
          value={() => {
            if (group) {
              return newProject &&
                newProject.services &&
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2?.[group]?.[field]
                ? newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2?.[group]?.[field]
                : defaultValue
                ? defaultValue
                : "";
            } else {
              return newProject &&
                newProject.services &&
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2?.[field]
                ? opts2[field]
                : defaultValue
                ? defaultValue
                : "";
            }
            // return opts2 && opts2.services
            //   ? opts2[field]
            //   : defaultValue
            //   ? defaultValue
            //   : "";
          }}
          disabled={!create}
          className={`mt-6 ${stacked && "flex flex-col w-96"} ${
            !stacked && "grid"
          } gap-y-6 grid-cols-1 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3  2xl:grid-cols-${cols}   sm:gap-x-12`}
          // onChange={(e) => handleSelect("options", e, field, service)}
          onChange={(e) => handleSelect(e, service, field)}
          options={options}
        />
      </div>
      {newProject &&
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2.weekday_hours == "custom" && (
          <div className="sm:col-span-full mt-4">
            <label
              htmlFor={field}
              className="block text-sm font-medium text-slate-700"
            >
              Custom Weekday Hours
            </label>

            {!create && (
              <div className="mt-1">
                <ReadOnlyAnswer
                  type="text"
                  answer={
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2[field]
                  }
                />
              </div>
            )}
          </div>
        )}
    </div>
  );
};
export const Checkboxes = (props) => {
  const {
    newProject,
    onFieldChange,
    opts2,
    label,
    description,
    service,
    field,
    create,
    options,
    tooltip,
    fieldType,
  } = props;
  const defaultValue = props.defaultValue ? props.defaultValue : false;
  const defaultChecked = props.defaultChecked ? props.defaultChecked : false;
  let optionsField = field + "_options";
  useEffect(() => {
    //if defaultValue is set, call onFieldChange to set the default value
    let e = {
      target: { name: field, value: true },
    };

    if (
      defaultChecked &&
      create &&
      newProject?.services[
        newProject?.services?.findIndex((el) => el.service === service)
      ].opts2[field] === undefined
    ) {
      onFieldChange(
        "options",

        e,

        field,
        service,
        null,
        true,
        optionsField
      );
    }
  }, [defaultChecked]);
  return (
    <div className="col-span-6">
      <div className="w-full relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id={field}
            name={field}
            type="checkbox"
            disabled={!create}
            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
            // value={opts2.project_reporting && opts2.project_reporting}\
            checked={
              newProject &&
              newProject.services[
                newProject.services.findIndex((el) => el.service === service)
              ].opts2[field]
            }
            onChange={(e) => {
              onFieldChange(
                "options",

                e,

                field,
                service,
                null,
                true,
                optionsField
              );
            }}
          />
        </div>
        <div className="ml-3 text-sm">
          <Label sm bold htmlFor={field} className="">
            {label}{" "}
            {create && tooltip && tooltip.text && (
              <QuestionToolTip
                text={tooltip.text}
                field={tooltip.title}
                size={tooltip.size || "md"}
                html={tooltip.html || false}
              />
            )}
          </Label>
          <p className="text-slate-500">{description}</p>
          {options &&
            options.length > 0 &&
            opts2 &&
            opts2[field] &&
            opts2[field] == true && (
              <div className="mt-2 space-y-2">
                {/* Subcheckboxes */}
                {/* map options */}
                {options &&
                  options.length > 0 &&
                  options.map((option) => {
                    return (
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id={option.value}
                              name={option.value}
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2 && opts2[optionsField]
                                  ? opts2[optionsField][option.value]
                                  : false
                              }
                              defaultChecked={
                                opts2 && opts2[optionsField]
                                  ? opts2[optionsField][option.value]
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,
                                  option.value,
                                  service,
                                  optionsField
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <Label sm normal htmlFor={option.value} className="">
                            {option.label}
                          </Label>
                        </div>
                      </div>
                    );
                  })}

                {/* Other Confirmation */}
                <div className="relative flex items-start">
                  <div className="relative flex ">
                    <div className="flex h-5 items-center">
                      <input
                        id="other_confirmation"
                        name="other_confirmation"
                        type="checkbox"
                        disabled={!create}
                        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                        value={
                          opts2[optionsField] &&
                          opts2[optionsField].other_confirmation
                            ? opts2[optionsField].other_confirmation
                            : false
                        }
                        defaultChecked={
                          opts2[optionsField] &&
                          opts2[optionsField].other_confirmation
                            ? opts2[optionsField].other_confirmation
                            : false
                        }
                        onChange={(e) => {
                          onFieldChange(
                            "options",
                            //if checked, true, else false
                            e,

                            "other_confirmation",
                            service,

                            optionsField
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={"other_confirmation"}
                      className="text-slate-700"
                    >
                      Other Reporting
                    </label>
                  </div>
                </div>
                {opts2 &&
                  opts2[optionsField] &&
                  opts2[optionsField].other_confirmation == true && (
                    <div className="col-span-6">
                      <label
                        htmlFor={field}
                        className="block text-sm font-medium text-slate-700"
                      >
                        Other reporting
                      </label>
                      {!create && (
                        <ReadOnlyAnswer
                          type="text"
                          answer={opts2[optionsField].other_details}
                        />
                      )}
                      {create && (
                        <p className="mt-2 text-sm text-slate-500">
                          Write a few sentences to describe your required
                          reporting.
                        </p>
                      )}
                      {create && (
                        <div className="w-full mt-1">
                          <textarea
                            id="description"
                            name="description"
                            rows={3}
                            value={opts2[optionsField].other_details}
                            onChange={(e) => {
                              onFieldChange(
                                "options",
                                //if checked, true, else false
                                e,

                                "other_details",
                                service,

                                optionsField
                              );
                            }}
                            className="block  w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                            defaultValue={""}
                          />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export const PricingInput = (props) => {
  const { label, service, field, fullQuote, response, onFieldChange } = props;
  return (
    <div className="relative w-full col-span-full ">
      <label htmlFor={field} className="text-sm font-medium mb-3 capitalize">
        Price for {utils.cleanUpKeys(field)}
      </label>
      <div className="pb-3 items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
        $
      </div>
      <input
        id="price"
        name="price"
        type="text"
        autoComplete="price"
        disabled={fullQuote && fullQuote.status == "submitted"}
        required
        className="block w-full min-w-full max-w-full px-10 py-3 placeholder-gray-500  focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
        placeholder={`Enter your ${utils.cleanUpKeys(field)} pricing.`}
        value={
          response && response[service] && response[service]["price"]
            ? response[service][field]
            : ""
        }
        onChange={(e) => {
          onFieldChange(e, service);
        }}
      />
    </div>
  );
};

export const TextArea = (props) => {
  const {
    label,
    description,
    service,
    field,
    create,
    opts2,
    placeholder,
    tooltip,
    onFieldChange,
    newProject,
    required,
  } = props;
  const [err, setErr] = useState(false);
  useEffect(() => {
    let displayValue = "";
    displayValue =
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2[field];
    if (
      required &&
      (displayValue == "" || displayValue == null || !displayValue)
    ) {
      setErr(true);
    } else {
      setErr(false);
    }
  }, [newProject]);
  return (
    <div className="sm:col-span-full mt-4">
      <label
        htmlFor={field}
        className="block text-sm font-medium text-slate-700"
      >
        {label}
        {create && tooltip && tooltip.text && (
          <QuestionToolTip
            text={tooltip.text}
            field={tooltip.title}
            size={tooltip.size || "md"}
            html={tooltip.html || false}
          />
        )}
      </label>

      {create && (
        <div>
          <p className="mt-1 text-sm text-slate-600">{description}</p>
          <div className="mt-1 shadow-sm">
            <textarea
              id={field}
              name={field}
              rows={3}
              value={
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2[field]
              }
              placeholder={placeholder}
              onChange={(e) => {
                onFieldChange(
                  "options",
                  //if checked, true, else false
                  e,

                  field,
                  service
                );
              }}
              // className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              className={`block w-full rounded-md flex-1  rounded-md border-gray-300 ${
                required && err ? "border-l-4 border-l-red-500" : ""
              } focus:border-brand-500 focus:ring-brand-500 sm:text-sm`}
              defaultValue={""}
            />
          </div>
        </div>
      )}
      {!create && (
        <div className="mt-1">
          <ReadOnlyAnswer
            type="text"
            answer={
              newProject.services[
                newProject.services.findIndex((el) => el.service === service)
              ].opts2[field]
            }
          />
        </div>
      )}
    </div>
  );
};

export const RadioGroupMulti = (props) => {};

export const Section = (props) => {
  const { label, description, create, tooltip } = props;
  return (
    <div className="sm:col-span-6">
      <h3 className="text-large font-semibold leading-6 text-slate-900">
        {label}
        {create && tooltip && tooltip.text && (
          <QuestionToolTip
            text={tooltip.text}
            field={tooltip.title}
            size={tooltip.size || "md"}
            html={tooltip.html || false}
          />
        )}
      </h3>
      {create && (
        <Paragraph sm className="">
          {description}
        </Paragraph>
      )}
    </div>
  );
};

export const SectionDivider = () => {
  return <div className="my-4 sm:col-span-6 border-b border-gray-200" />;
};
