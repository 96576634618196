//Hooks
import React, { useState, useRef, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

//External Libraries
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Icon } from "@iconify/react";

//Icons
import {
  ArrowDownTrayIcon,
  CurrencyDollarIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  PaperClipIcon,
} from "@heroicons/react/20/solid";

//Elements
import Button from "../../../elements/Button";
import Tabs from "../../component/Tabs";

//Components
import RightActionArea from "../../../components/ActionAreaRight";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { HeadingTwo } from "../../../components/Typography/HeadingTwo";

//Form Elements
import EmailingInfo from "../components/EmailingInfo";
import JurisdictionInput from "../components/JurisdictionInput";
import MailingInfo from "../components/MailingInfo";
import CallCenterInfo from "../components/CallCenterInfo";
//API's
import { Solicitations } from "../../../modules/_solicitations";
import { Responses } from "../../../modules/_responses";
import Logo from "../../../images/logo.png";
import { cleanup } from "@testing-library/react";
import { set } from "date-fns";
import CreditMonitoringInfo from "../components/CreditMonitoringInfo";
import Divider from "../../../components/Divider";
import Tip from "../../../components/Tip";
import Chips from "../../../components/Chips";
import { Label } from "../../../components/Typography/FieldLabel";
import * as constants from "../../../constants/constants";
import { utils } from "../../../modules/_utils";
import SupplierInfo from "../components/SupplierInfo";
import { _company } from "../../../modules/_company";
import ModalBasic from "../../../components/ModalBasic";
import PdfViewer from "../../../components/PdfViewer";
import { Files } from "../../../modules/_files";
import Welcome from "../components/Welcome";
import Skeleton from "../../../components/Skeleton";
import { parse } from "node-html-parser";
import { _user } from "../../../modules/_user";
import DataMiningInfo from "../components/DataMiningInfo";
import PricingResponseForm from "./PricingResponseForm";
import InputWithDropdown from "../../../components/InputWithDropdown";
import Drawer from "../../../components/Drawer";
import HelpItems from "../../../components/HelpComponent/HelpItems";
import NotificationBanner from "../../../components/NotificationBanner";
import CommentButton from "../../../components/CommentButton";
import { _comments } from "../../../modules/_comments";
import jwtDecode from "jwt-decode";
import { _auth } from "../../../modules/_auth";
import { FeatureFlag } from "../../../components/FeatureFlag";
import { features } from "../../../modules/_features";
import { Dialog, Transition } from "@headlessui/react";
import Badge from "../../../components/Badge";
import UploadComponent from "../../component/UploadComponent";
import AttachmentComponent from "../../component/AttachmentComponent";
import { async } from "q";
import Assumptions from "../components/Assumptions";
import { ErrorLogging } from "../../../modules/_error_logging";
import Tooltip from "../../../components/Tooltip";
import DialogBox from "../../../components/Dialog";
import ForensicsInfo from "../components/ForensicsInfo";
import ClassActionInfo from "../components/ClassActionInfo";
function VendorResponsePage(props) {
  console.log("V2 Page");
  //Refs
  const printRef = useRef();
  const timerRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const debug = true;

  //UI States
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [serviceDrawerOpen, setServiceDrawerOpen] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [showBannerModal, setShowBannerModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState([]);
  const [saved, setSaved] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [tabs, setTabs] = useState([
    {
      name: "Overview",
      id: "overview",
      active: false,
    },
  ]);
  const [selectedTab, setSelectedTab] = useState("Overview");
  const [responseTabs, setResponseTabs] = useState([]);
  // const [selectedResponseTab, setSelectedResponseTab] = useState(
  //   responseTabs && responseTabs[0] ? responseTabs[0].name : null
  // );
  const [selectedService, setSelectedService] = useState(null);
  const [err, setErr] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [submissionRollbackError, setSubmissionRollbackError] = useState();
  const [submissionRollbackErrorMessage, setSubmissionRollbackErrorMessage] =
    useState();

  const [success, setSuccess] = useState(null);
  const [submitErr, setSubmitErr] = useState(false);
  const [submitErrMessage, setSubmitErrMessage] = useState("");
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadFile, setLoadFile] = useState(false);
  //Data States
  const [vendor, setVendor] = useState({});
  const [solicitation, setSolicitation] = useState({});
  const [response, setResponse] = useState({});
  const [fullQuote, setFullQuote] = useState({});
  const [responseId, setResponseId] = useState(null);
  const [responseTotal, setResponseTotal] = useState(0);
  const [project, setProject] = useState({});
  // const [id, setId] = useState(
  //   location.state.solicitation && location.state.solicitation._id
  //     ? location.state.solicitation._id
  //     : null
  // );
  const [submitted, setSubmitted] = useState(false);

  const [guestId, setGuestId] = useState(null);
  const [selectedFileBlob, setSelectedFileBlob] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [colSpan, setColSpan] = useState(7);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentDrawerOpen, setCommentDrawerOpen] = useState(false);
  const [autosaving, setAutosaving] = useState(false);
  const [featureFlags, setFeatureFlags] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [profile, setProfile] = useState(
    // _auth.getGuestProfile()
    _auth.getGuestProfile()
  );

  //constants
  const inviteId = location.state.inviteId;

  const {
    userId,
    email,
    InvitedLink,
    InvitedDate,
    solicitationId,
    tenantId,
    guestToken,
    InvitedID,
    vendorCompanyId,
    fromVendorProjectList,
  } = location.state;
  const tabOrder = constants.SOLICITATION_TAB_ORDER;
  // const profile = JSON.parse(_auth.getGuestProfile());
  console.log("vendor location.state", location.state);

  useEffect(() => {
    //useEffect to get initial data
    (async () => {
      {
        // setGuestId(invite._id);
        // setProfile(location.state.profile);
        // setId(solicitationId);
        // setSolicitation(solicitation);
        // setResponseId(quote._id);
        // setResponse(quote.services || {});
        // setAttachments(quote.attachments);
        // setFullQuote({
        //   ...fullQuote,
        //   tenantId: solicitation?.tenantId?._id,
        //   companyId: solicitation?.tenantId?.tenantCompanyID,
        // });
        // setVendor(quote.vendor);
        // setSaved(["Supplier Information"]);
      }

      //update guestProfile sessionStorage set userId to invite.guestId
      let prf = JSON.parse(_auth.getGuestProfile());
      console.log("useEffect --> setting prf", prf);
      // prf.user_id = invite.userId;
      // sessionStorage.setItem("guestProfile", JSON.stringify(prf));
      setProfile(prf);
      console.log("useEffect --> setting state prf", prf);

      console.log("LOCATION.STATE", location.state);
      //get Response by inviteId
      let response = await Responses.getResponseByInviteId({
        inviteId: location.state.inviteId,
      });
      if (response && response != null) {
        console.log("useEffect get initial data - response found", response);
      } else {
        //create a new response
        console.log(
          "useEffect get initial data - no response found",
          response,
          "creating new response"
        );
        response = await Responses.create({
          from: "getInitialData - create",

          inviteId: inviteId,
          solicitationId: solicitationId,
          projectId: null, //add to invite
          status: "draft",
          // vendorId: guestId,
          tenantId: tenantId,
          companyId: null,
          services: {},
          submittedByEmail: location.state.email ? location.state.email : "",
          submittedByName: location.state.name ? location.state.name : "",
          submittedByCompany: "",
          attachments: [],
        });
      }
      if (response) {
        console.log("Response found or created", response);
        setFullQuote(response);
        setResponse(response.services);
        setAttachments(response.attachments);
        setVendor(response.vendor);
        if (
          response.vendorCompanyId &&
          response.vendorCompanyId != null &&
          response.vendorCompanyId != undefined
        ) {
          setSaved(["Supplier Information"]); //how to determine?
        } else {
          console.log("No vendor company id found");
        }
      } else {
        console.log("No response found and no response created");
      }
      let solicitation = await Solicitations.getById({
        id: solicitationId,
        solicitationId: solicitationId,
        fromVendor: true,
      });
      setSolicitation(solicitation);
      if (solicitation && solicitation.services) {
        if (!response.services) {
          response.services = {};
        }
        solicitation.services.forEach((service) => {
          console.log("Solicitation services", service.service);
          if (!response.services[service.service]) {
            response.services[service.service] = {};
          }
        });
        console.log("Setting solicitation services", response);
        setFullQuote(response);
        setResponse(response.services);
      }

      console.log("RESPONSE getByInviteId", response);
    })();
  }, [inviteId]);

  useEffect(() => {
    console.log("DEBUG 12/21 --> Response State", response);
  }, [response]);

  useEffect(() => {
    let useEffectLogs = [];
    if (fullQuote && fullQuote.status == "draft" && fullQuote.submittedOn) {
      debug &&
        useEffectLogs.push({
          message: "USEEFFECT --> response not matching",
          details: fullQuote,
          time: Date.now(),
        });

      debug &&
        ErrorLogging.LogFrontEnd(
          "USEEFFECT --> response not matching",
          useEffectLogs
        );
    }
  }, [fullQuote]);

  useEffect(() => {
    setTimeout(() => {
      setShowBanner(false);
    }, 250);
  }, []);
  useEffect(() => {
    console.log(fileList, "filessss");
  }, [fileList]);
  useEffect(() => {
    (async () => {
      let flags = await features.featureFlags();
      // if (flags) {
      //   setFeatureFlags(flags);
      // }
    })();
  }, []);

  useEffect(() => {
    //move this to useMemo so it doesn't check after the first time
    let item = solicitation;

    console.log("useMemo item", item, fullQuote.accept_nda);
    let myTabs = [];
    let orderedTabs = [...tabs];
    if (
      (item.nda_required == false &&
        item.services &&
        item.services.length > 0) ||
      (item.nda_required == true &&
        fullQuote.accept_nda &&
        fullQuote.accept_nda == true &&
        item.services &&
        item.services.length > 0)
    ) {
      console.log("useMemo all checks passed", item, fullQuote);
      //if tabse are not aleady there, add them
      // let initialSaved = [];
      if (!tabs.find((tab) => tab.name == "Supplier Information")) {
        orderedTabs.push({
          name: "Supplier Information",
          id: "supplier_information",
          active: true,
        });
        if (!tabs.find((tab) => tab.name == "Attachments")) {
          orderedTabs.push({
            name: "Attachments",
            id: "attachments",
            active: false,
          });
        }
      }

      console.log("Added supplier to ordered Tabs", orderedTabs);

      item.services.forEach((service) => {
        if (
          !tabs.find((tab) => tab?.name == service?.service) &&
          service.service != "Supplier Information"
        ) {
          myTabs.push({
            name: service.service,
            id: service.id,
            active: false,
          });

          // manageSaved(service.service, false, "useEffect");
          // initialSaved.push(service.service);
        }
        if (item.attachments.length > 0) {
          // myTabs.push({
          //   name: "Attachments",
          //   id: "attachments",
          //   active: true,
          // });
        }
      });

      utils.orderTabs(myTabs, tabOrder, orderedTabs);
      // if (!orderedTabs.find((tab) => tab.name == "Summary")) {
      //   orderedTabs.push({
      //     name: "Summary",
      //     id: "summary",
      //     active: false,
      //   });
      // }

      console.log("Setting additional myTabs", orderedTabs);
      setTabs(orderedTabs);
      // setSaved(initialSaved);
      // setSelectedTab(orderedTabs[0].name);
    }
  }, [fullQuote.accept_nda, solicitation, attachments]);

  useEffect(() => {
    console.log("useEffect --> selectedTab changed", selectedTab);
    setColSpan(
      selectedTab !== "Summary" &&
        selectedTab !== "Overview" &&
        selectedTab !== "Supplier Information" &&
        selectedTab !== "Attachments"
        ? 6
        : 12
    );
  }, [selectedTab]);

  useEffect(() => {
    console.log("File attachments changed, autosaving", attachments);
    if (!fullQuote) return;
    if (!fullQuote.status || fullQuote.status === undefined) return;
    if (
      fullQuote &&
      fullQuote.status &&
      fullQuote.status.toLowerCase() == "submitted"
    ) {
      return;
    } else {
      setAutosaving(true);

      console.log("Autosaving attachments ", fullQuote.status);
      // debugger;

      handleSaveServiceClick(
        null,
        "attachments",
        attachments,
        "autosave-from-attachments"
      );

      setAutosaving(false);
    }
  }, [fullQuote.attachments]);

  const prevResponseRef = useRef();

  useEffect(() => {
    prevResponseRef.current = response;
  }, []);

  useEffect(() => {
    if (!fullQuote) return;
    if (!fullQuote.status || fullQuote.status === undefined) return;
    if (
      fullQuote &&
      fullQuote.status &&
      fullQuote.status.toLowerCase() == "submitted"
    ) {
      return;
    }

    if (prevResponseRef.current !== response) {
      console.log("Response changed, autosaving");
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(async () => {
        setAutosaving(true);
        handleSaveServiceClick(null, selectedTab, response, "autosave");
        setTimeout(() => {
          setAutosaving(false);
        }, 800);
        // debugger;
      }, 1000);
      return () => clearTimeout(timerRef.current);
    } else {
      console.log("DEBUG --> Response did not change");
    }

    prevResponseRef.current = response;
  }, [response]);

  useEffect(() => {
    //find the selected service in the solicitation based on selected tab
    if (tabs && tabs.length > 0) {
      let service = solicitation.services?.find(
        (service) => service?.service == selectedTab
      );
      if (service) {
        console.log("Setting selected service");
        setSelectedService(service);

        let quoteTabs = [];
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    console.log("UPDATED RESPONSE -> checking for errors", response);
    // check to ensure that all services from the solicitation are in the response
    //and that all have a price
    //if not, set submitErr to true
    //if so, set submitErr to false
    let err = false;
    let errMessage = "";
    if (solicitation && solicitation.services && response) {
      solicitation.services.forEach((service) => {
        if (
          !response[service.service] ||
          !response[service.service].price ||
          response[service.service].price == ""
        ) {
          err = true;
          errMessage = "To submit a bid, a price is required for all services.";
        }
      });
    }
    setSubmitErr(err);
    setSubmitErrMessage(errMessage);
  }, [response]);

  useEffect(() => {
    console.log("UPDATED ERR -> checking for submitErr", err);
  }, [err]);

  useEffect(() => {
    setSubmitted(
      (response && !response.status) ||
        (response && response.status !== "draft") ||
        (response && response.status == "submitted")
      // response && response.status && response.status == "submitted" && true
    );
  }, [response]);

  useEffect(() => {
    setProject(
      location.state && location.state.project ? location.state.project : {}
    );
  }, []);

  //Report selected tab to console on change
  useEffect(() => {
    console.log("Setting selected tab", selectedTab);
  }, [selectedTab]);

  //Add up the total of the response for all services
  useEffect(() => {
    let total = 0;
    if (response) {
      Object.keys(response).forEach((key) => {
        if (
          response[key].price &&
          response[key].price > 0 &&
          !isNaN(response[key].price)
        ) {
          total += +response[key].price;
        }
      });
    }
    setResponseTotal(total);
  }, [response]);
  useEffect(() => {
    console.log("UseEffect:Updating full response", response);
  }, [response]);
  useEffect(() => {
    (async () => {
      console.log("UseEffect:Fetching comments");
      const cmnts = await _comments.getCommentsById({
        commentType: "solicitation",
        solicitationId: solicitation._id,
      });
      //add cmnts to comments state array
      if (cmnts) {
        setComments(cmnts);
      }
    })();
  }, [solicitation]);

  const redirectAfterSubmission = async () => {
    let isUser;
    let hasCompany;
    let hasTenants;
    let logs = [];
    if (fromVendorProjectList) {
      history.goBack();
    } else {
      // if (submitted == true) {
      //see if this person is a user
      console.log(
        "redirectAfterSubmission TESTING --> Submitted is true, checking user",
        location.state.email
      );
      debug &&
        logs.push({
          message:
            "redirectAfterSubmission TESTING --> Submitted is true, checking user",
          details: location.state.email,
          time: Date.now(),
        });

      const user = await _user.getUserByEmail(location.state.email);
      if (user && user.length > 0) {
        console.log("TESTING --> User Found, setting isUser to true");
        isUser = true;
        debug &&
          logs.push({
            message:
              "redirectAfterSubmission TESTING --> User Found, setting isUser to true",
            details: user,
            time: Date.now(),
          });
        // return;
      } else {
        console.log("TESTING --> User not found, setting isUser to false");
        isUser = false;
        debug &&
          logs.push({
            message:
              "redirectAfterSubmission TESTING --> User not found, setting isUser to false",
            details: user,
            time: Date.now(),
          });
        // console.log(
        //   "Should we ask to create a user? Should we email the company admin and tell them? ",
        //   user
        // );
      }

      //get company tenant count
      console.log("TESTING --> Getting company by id");
      debug &&
        logs.push({
          message: "redirectAfterSubmission TESTING --> Getting company by id",
          details: fullQuote.vendorCompanyId,
          time: Date.now(),
        });

      const company = await _company.getCompanyById(
        // location.state.email && location.state.email.split("@")[1]
        fullQuote.vendorCompanyId
      );
      if (company) {
        console.log("TESTING --> COMPANY FOUND, setting hasCompany to true");
        hasCompany = true;
        debug &&
          logs.push({
            message:
              "redirectAfterSubmission TESTING --> COMPANY FOUND, setting hasCompany to true",
            details: company,
            time: Date.now(),
          });

        if (
          company.company &&
          company.company.companyTenants &&
          company.company.companyTenants.length > 0
        ) {
          console.log(
            "TESTING --> Company has tenants, setting hasTenants to true"
          );
          hasTenants = true;
          debug &&
            logs.push({
              message:
                "redirectAfterSubmission TESTING --> Company has tenants, setting hasTenants to true",
              details: company,
              time: Date.now(),
            });
        } else {
          console.log(
            "TESTING --> Company does not have tenants, setting hasTenants to false"
          );
          hasTenants = false;
          debug &&
            logs.push({
              message:
                "redirectAfterSubmission TESTING --> Company does not have tenants, setting hasTenants to false",
              details: company,
              time: Date.now(),
            });
        }
      } else {
        console.log(
          "TESTING --> COMPANY NOT FOUND, setting hasCompany to false"
        );
        hasCompany = false;
        debug &&
          logs.push({
            message:
              "redirectAfterSubmission TESTING --> COMPANY NOT FOUND, setting hasCompany to false",
            details: company,
            time: Date.now(),
          });
      }

      if (isUser || hasTenants) {
        //for now we redirect and tell the customer to email their admin if they don't have an account
        //future state - tell user whether or not they have an account
        //future state - ask if we should email the admin on their behalf
        //further future state - sign the user up? how would this work?

        // console.log(
        //   "TESTING --> isUser is",
        //   isUser,
        //   "hasTenants is",
        //   hasTenants
        // );
        // console.log(
        //   "TESTING --> User is ",
        //   user,
        //   " and company has ",
        //   company.company.companyTenants.length,
        //   " tenants"
        // );
        console.log("TESTING --> Navigating to vendor options page");
        debug &&
          logs.push({
            message:
              "redirectAfterSubmission TESTING --> Navigating to vendor options page",
            details: company,
            time: Date.now(),
          });
        ErrorLogging.LogFrontEnd("redirectAfterSubmission", logs);
        history.push({
          pathname: "/vendor-options",
          state: {
            info: vendor,
            email: location.state.email,
            company: company,
            user: user,
            isUser: true,
            hasCompany: true,
          },
        });
      } else {
        //navigate to signup page with company info and user info
        console.log(
          "TESTING --> isUser is",
          isUser,
          "hasTenants is",
          hasTenants
        );
        console.log("TESTING --> Navigating to signup page, info:", vendor);
        debug &&
          logs.push({
            message:
              "redirectAfterSubmission TESTING --> Navigating to signup page, info:",
            details: vendor,
            time: Date.now(),
          });
        ErrorLogging.LogFrontEnd("redirectAfterSubmission", logs);
        history.push({
          pathname: "/signup",
          state: {
            info: vendor,
            email: location.state.email,
          },
        });
      }
      // } else {
      //   console.log("TESTING --> Submitted is false, not redirecting");
      // }
    }
  };
  const handlePostComment = async () => {
    console.log("Posting comment", commentText);
    console.log(solicitation, "solicia");
    console.log(fullQuote.solicitationId, "solicia");
    setCommentLoading(true);
    let data = {
      comment: commentText,
      commentType: "solicitation",
      solicitationId: fullQuote.solicitationId
        ? fullQuote.solicitationId
        : solicitation._id,
      responseId: null,
      projectId: fullQuote.projectId,
      authorType: "vendor",
      company:
        fullQuote && fullQuote.vendor && fullQuote.vendor.companyName
          ? fullQuote.vendor.companyName
          : "",
      email: fullQuote.submittedByEmail,
    };
    setTimeout(() => {
      (async () => {
        if (!commentText) return;
        if (commentText == "") return;

        const comment = await _comments.addComment(data);
        setComments([...comments, comment]);
        setCommentText("");
        // props.updateCommentCount("add");
        setCommentLoading(false);
      })();
    }, 500);
  };
  const handleLike = async (e, commentID) => {
    console.log(commentID, "id-comment");
  };
  const handleAcceptNDA = () => {};

  const handleSetPdfModalOpen = (file, fileType) => {
    console.log("Setting pdf modal open", file, fileType);
    if (pdfModalOpen) {
      setPdfModalOpen(false);
      setSelectedFile(null);
      setSelectedFileName(null);
      setSelectedFileBlob(null);
      setLoadFile(false);
    } else {
      console.log("Setting selected file", file);
      setSelectedFile(file);
      setSelectedFileBlob(file.path);
      getSelectedFile(file._id, fileType);
      setPdfModalOpen(true);
      setTimeout(() => {
        setLoadFile(true);
      }, 750);
    }
  };

  const getSelectedFile = async (id, fileType) => {
    console.log("Getting selected file", id, fileType);
    if (id) {
      console.log("Getting file download link from server", id);
      const file = await Files.getFileById(id, fileType, solicitation._id);
      console.log("Got file download link from server", file);
      // let name = file.url.split("%5c");
      // name = name[name.length - 1];
      let name = file.name;
      setSelectedFileName(name);
      console.log("Setting selected fileName", name);
      setSelectedFileBlob(file.url);
      return file.url;
    }
  };

  const handleDownloadHTMLPdf = async () => {
    manageLoading("pdf", true);
    const elementHTML = printRef.current;
    var doc = new jsPDF();

    // Source HTMLElement or a string containing HTML.
    // var elementHTML = document.querySelector("#contentToPrint");

    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save("document-html.pdf");
      },
      margin: [10, 10, 10, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 190, //target width in the PDF document
      windowWidth: 675, //window width in CSS pixels
    });

    manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };
  const handleDownloadPdf = async () => {
    manageLoading("pdf", true);
    //ORAN
    const root = parse(printRef.current);
    console.log("ROOT HTML PARSED", root);
    manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };

  const acceptNDA = async () => {
    setFullQuote({
      ...fullQuote,
      accept_nda: true,
    });
    // await handleSaveServiceClick(null, "update", newFullQuote);

    let responseId = await Responses.create({
      from: "acceptNDA",
      solicitationId: solicitation._id,
      projectId: solicitation.projectId,
      companyId: solicitation.companyId,
      tenantId: solicitation.tenantId?._id,
      vendorId: guestId,
      accept_nda: true,
      vendorCompanyId: fullQuote && fullQuote.vendorCompanyId,
      inviteId: inviteId,
      services: response,
      submittedByEmail: location.state.email ? location.state.email : "",
      submittedByName: location.state.name ? location.state.name : "",
      submittedByCompany: vendor.companyName
        ? vendor.companyName
        : location.state.company,
      // submittedByCompany: location.state.company ? location.state.company : "",
    });
  };

  const updateResponse = async (data) => {
    console.log("updateResponse data", data);
    if (
      !fullQuote ||
      (fullQuote &&
        fullQuote.status &&
        fullQuote.status.toLowerCase() == "submitted")
    ) {
      console.log("Full quote is null or submitted", fullQuote);
      return;
    }
    console.log("Updating newCompany reponse data", fullQuote, data);

    const vendor = data;
    const vendorCompanyId = data._id;
    // const update = { vendor: vendor, vendorCompanyId: vendorCompanyId };
    let newFullQuote = fullQuote;
    newFullQuote.vendor = vendor;
    newFullQuote.vendorCompanyId = vendorCompanyId;
    setFullQuote(newFullQuote);
    let updated = await handleSaveServiceClick(
      null,
      "update",
      newFullQuote,
      "updateResponse"
    );
    if (updated) {
      setVendor(vendor);
      // setFullQuote(data);
      console.log("Full quote updated", updated);
    }
    manageSaved("Supplier Information", true, "updateResponse");
  };

  //Utility Functions
  const cleanUpKeys = (key) => {
    let newKey = key.replace(/([A-Z])/g, " $1");

    newKey = newKey.replace(/_/g, " ");
    //capitalize first letter of each word
    let finalKey = newKey.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    // let finalKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);
    console.log("FinalKey", finalKey);
    return finalKey;
  };

  const manageLoading = (item, bool) => {
    console.log("Manage loading", item, bool, "current loading state", loading);
    //if item is already in loading array and bool is true, do nothing
    if (loading.includes(item) && bool == true) {
      console.log(
        "Manage loading - already in loading array and bool is true",
        loading
      );
      setLoading(item);
    } else if (loading.includes(item) && bool == false) {
      //if item is already in loading array and bool is false, remove it
      let newLoading = loading.filter((i) => i !== item);
      console.log(
        "Manage loading - already in loading array and bool is false",
        loading,
        newLoading
      );
      setLoading(newLoading);
    } else if (!loading.includes(item) && bool == true) {
      //if item is not in loading array and bool is true, add it
      let newLoading = [...loading, item];
      console.log(
        "Manage loading - not in loading array and bool is true, adding",
        loading,
        newLoading
      );

      setLoading(newLoading);
    } else {
      console.log(
        "Manage loading - not in loading array and bool is false",
        loading
      );
      let newLoading = loading.filter((i) => i !== item);
      //if item is not in loading array and bool is false, do nothing
      setLoading(newLoading);
    }
  };

  const isLoading = (item) => {
    if (loading.includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  const manageSaved = (item, bool, source) => {
    console.log("Manage saved - source", source);
    console.log("Manage saved", item, bool, "current saved state", saved);

    //if item is already in loading array and bool is true, do nothing
    if (saved.includes(item) && bool == true) {
      console.log(
        "Manage saved - already in saved array and bool is true",
        saved
      );
      // setSaved(item);
    } else if (saved.includes(item) && bool == false) {
      //if item is already in loading array and bool is false, remove it
      let newSaved = saved.filter((i) => i !== item);
      console.log(
        "Manage saved - already in saved array and bool is false",
        saved,
        newSaved
      );
      setSaved(newSaved);
    } else if (!saved.includes(item) && bool == true) {
      //if item is not in loading array and bool is true, add it
      let newSaved = [...saved, item];
      console.log(
        "Manage saved - not in saved array and bool is true, adding",
        saved,
        newSaved
      );

      setSaved(newSaved);
    } else {
      console.log("Manage saved - not in saved array and bool is false", saved);
      let newSaved = saved.filter((i) => i !== item);
      //if item is not in loading array and bool is false, do nothing
      setSaved(newSaved);
    }
  };

  const isSaved = (item) => {
    console.log("Is saved", item, saved);
    // if (saved.includes("all")) {
    //   return true;
    // }
    if (saved && saved.includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  //Navigation Functions
  const handleClickBack = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/competencies",
      state: {},
    });
  };

  //Click Handler Functions
  const handleCleanUpClick = async (e) => {
    e.preventDefault();
    console.log("Cleaning up keys");
    manageLoading("cleanUp", true);
    if (!fullQuote._id) {
      console.log("No fullQuote id");
      return;
    }

    const deleted = await Responses.delete({
      id: fullQuote._id,
      solicitationId: solicitation._id,
    });
    console.log("Waiting for delete response");
    if (deleted) {
      console.log("Deleted response", deleted);
      //refresh the page
      // setTimeout(() => {
      window.location.reload();
      // }, 1000);
    } else {
      manageLoading("cleanUp", false);
      console.log("Error deleting response");
    }
  };
  const handleEditClick = async (e) => {
    e.preventDefault();
    console.log("Cleaning up keys");
    manageLoading("setEdit", true);
    if (!fullQuote._id) {
      console.log("No fullQuote id");
      return;
    }

    const updated = await Responses.SetEditStatus({
      id: fullQuote._id,
      solicitationId: solicitation._id,
    });
    console.log("Waiting for edit response");
    if (updated) {
      let logs = [];
      console.log("Edit response", updated);
      //isntead of page refresh here, we should set status to "Draft"

      setTimeout(() => {
        let quote = fullQuote;
        quote.status = "draft";
        quote.isEditing = true;
        setFullQuote(quote);
        debug &&
          logs.push({
            message: "Edit response",
            details: { quote, fullQuote },
            time: Date.now(),
          });
        debug && ErrorLogging.LogFrontEnd("handleEditClick", logs);

        manageLoading("setEdit", false);
      }, 750);
    } else {
      manageLoading("setEdit", false);
      console.log("Error seting edit to response");
    }
  };
  const updateAttachments = async (e, file, action) => {
    e.preventDefault();
    console.log(file, "file-attachments", JSON.stringify(file));
    console.log(action, "action");
    if (action === "add") {
      let document = {
        name: file.originalname,
        size: file.size,
        type: file.mimetype,
        documentType: "Contract",
        path: file.path,
      };
      setAttachments([...attachments, document]);
      let quote = fullQuote;
      quote.attachments = [...attachments, document];
      setFullQuote(quote);
      sessionStorage.setItem(
        "attachments",
        JSON.stringify([...attachments, document])
      );
      // handleSaveServiceClick(null, "attachments", attachments);
    } else {
      let newFileList =
        attachments &&
        attachments.length > 0 &&
        attachments.filter((el) => el.path !== file.path);
      setAttachments(newFileList);
      let quote = fullQuote;
      quote.attachments = newFileList;
      setFullQuote(quote);
      // handleSaveServiceClick(null, "attachments", attachments);
    }
  };
  const handleSubmitBidClick = async (e, action) => {
    e.preventDefault();
    manageLoading(action, true);

    //save response
    console.log("Submitting fullQuote", JSON.stringify(fullQuote));
    const saved = await handleSaveServiceClick(e, action);

    if (saved) {
      let logs = [];
      //mark the response as complete and send an email to the buyer
      debug &&
        logs.push({
          message: "Getting ready to call handleMarkComplete",
          details: { saved, action },
          time: Date.now(),
        });
      console.log("Marking complete", action);
      const sent = await handleMarkComplete(e, saved, action);

      if (sent) {
        console.log("Vendor Response Sent", sent);
        debug &&
          logs.push({
            message:
              "Returned from handleMarkComplete, redirecting after submission",
            details: sent,
            time: Date.now(),
          });
      } else {
        debug &&
          logs.push({
            message:
              "Returned from handleMarkComplete, an error occurred, not redirecting",
            details: sent,
            time: Date.now(),
          });
        setTimeout(() => {
          manageLoading("submit", false);
        }, 4000);
        // setErr(true);
        // setErrMessage("Error sending response");
      }
    } else {
      console.log("Error saving response", saved);
      setTimeout(() => {
        manageLoading("submit", false);
      }, 5000);
      // setErr(true);
      // setErrMessage("Error saving response");
    }
  };
  const handleSaveServiceClick = async (e, service, update, ancestor) => {
    e && e.preventDefault();

    service ? (service = service) : (service = "save");
    setErr(false);
    setErrMessage("");
    manageLoading(service, true);

    console.log("Saving service", service, "update", update);

    // let attachmentsLocal = sessionStorage.getItem("attachments");
    // let parsedAttachments = JSON.parse(attachmentsLocal);
    // setAttachments(parsedAttachments);

    console.log("Saving response", response);
    let responseId = await Responses.create({
      ancestor: ancestor ? ancestor : "No ancestor",
      from: "handleSaveServiceClick",
      solicitationId: solicitation._id
        ? solicitation._id
        : location.state.solicitationId,
      projectId: solicitation.projectId,
      // status: !fullQuote.status ? "draft" : fullQuote.status,
      vendorId: guestId,
      tenantId: solicitation?.tenantId?._id,
      companyId: solicitation?.tenantId?.tenantCompanyID,
      vendorCompanyId: fullQuote && fullQuote.vendorCompanyId,
      inviteId: inviteId,
      services: response,
      submittedByEmail: location.state.email ? location.state.email : "",
      submittedByName: location.state.name ? location.state.name : "",
      submittedByCompany: vendor?.companyName
        ? vendor.companyName
        : location.state.company,
      attachments:
        attachments && attachments.length > 0
          ? attachments.map((val) => {
              return {
                name: val.name,
                path: val.path,
                type: val.type,
                _id: val._id,
              };
            })
          : [],
      // submittedByCompany: location.state.company ? location.state.company : "",
    });

    if (responseId) {
      console.log("Got responseId", responseId);
      responseId.services = response;
      //full solicitation
      if (responseId.services) {
        setResponse(responseId.services);
      }
      setAttachments(responseId.attachments);

      setResponseId(responseId._id);

      manageSaved(service, true, "handleSaveServiceClick");
      //COULD THIS BE THE PROBLEM?
      // setFullQuote(responseId);
      // console.log("Got responseId - loading", responseId);

      if (service != "submit") {
        console.log("ManageLoading-->", service, false);
        setTimeout(() => {
          manageLoading(service, false);
        }, 750);
      } else {
        console.log("ManageLoading-->", service, true);
        manageLoading(service, true);
      }
      console.log("Saved response, returning true");
      return responseId;
    } else {
      setTimeout(() => {
        manageLoading(service, false);
      }, 750);
      setErr(true);
      setErrMessage("Error saving response");
      console.log("Error saving response, returning false");
      return false;
    }
  };
  const handleMarkComplete = async (e, saved, action) => {
    let logs = [];
    // const debug = fullQuote.vendor._id === "6536a0fcc33a3c55eed2d1ee";
    const debug = true;
    console.log("DEBUG --> handleMarkComplete called", debug);
    //mark complete and submit
    e.preventDefault();
    // setLoading(true);
    //save response

    debug &&
      logs.push({
        message: "handleMarkComplete called",
        details: { saved, action },
        time: Date.now(),
      });
    console.log("Marking complete", action);

    let quote = await Responses.send(
      {
        solicitationId: solicitation._id,
        createdBy: solicitation.createdBy,
        services: response,
        id: saved._id,
        projectId: solicitation.projectId,
        companyId: solicitation?.tenantId?.tenantCompanyID,
        tenantId: solicitation?.tenantId?._id,
        status: action == "decline" ? "declined" : "submitted",
        submittedByEmail: location.state.email ? location.state.email : "",
        submittedByName: location.state.name ? location.state.name : "",
        // submittedByCompany: location.state.company ? location.state.company : "",
        submittedByCompany: vendor.companyName,
        vendor: vendor,
        // submittedByCompany: location.state.company ? location.state.company : "",
      },
      debug
    );
    if (quote) {
      console.log("Got submitted response", quote);

      debug &&
        logs.push({
          message: "Responses.send returned the new quote",
          details: quote,
          time: Date.now(),
        });

      let newStatus = quote && quote.status ? quote.status : null;
      debug &&
        logs.push({
          message: "VERIFYING RETURNED STATUS",
          details: newStatus,
          time: Date.now(),
        });

      setTimeout(() => {
        console.log("Call LogFrontEnd before redirect", logs);
        debug &&
          logs.push({
            message: "Redirecting after submission",
            details: quote,
            time: Date.now(),
          });
        debug && ErrorLogging.LogFrontEnd("Frontend Logs - success", logs);
        redirectAfterSubmission();
        // return quote;
      }, 2000);
    } else {
      debug &&
        logs.push({
          message: "Error sending response",
          details: quote,
          time: Date.now(),
        });
      setTimeout(() => {
        // setLoading(false);
        // setErr(true);
        // setErrMessage("Error sending response");
        setSubmissionRollbackError(true);
        setSubmissionRollbackErrorMessage(
          "Your response is saved, but we had a problem updating its status."
        );
        manageLoading(action, false);
        console.log("Your response is saved, but we had a problem ");
        debug &&
          logs.push({
            message: "Your response is saved, but we had a problem ",
            details: quote,
            time: Date.now(),
          });
        console.log("Call LogFrontEnd", logs);
        debug && ErrorLogging.LogFrontEnd("Frontend Logs - ERR", logs);
        return false;
      }, 1000);
    }
  };

  const handleFieldChange = (e, service, fromFunction) => {
    console.log(
      "handleFieldChange called",
      fromFunction,

      e.target.name,
      e.target.value
    );
    setSuccess(false);
    manageSaved(service, false);
    setErr(false);
    setErrMessage("");
    const { name, value } = e.target;
    if (name === "price") {
      if (isNaN(value)) {
        setErr(true);
        setErrMessage("Must be all numbers");
      }
    }
    if (value == undefined || !response || response == undefined) {
      console.log(
        "handleFieldChange Setting response - No value or response",
        value
      );
      return;
    } else {
      console.log("Setting response", { response, service, name, value });
      //check to see if the service exists in the response object
      //if not, create it
      if (!response[service]) {
        console.log("Creating new service", service);
        let newResponse = response;
        newResponse[service] = {};
        setResponse(newResponse);
      }
      // prevResponseRef.current = response;
      setResponse((prevState) => ({
        ...prevState,
        [service]: { ...prevState[service], [name]: value },
      }));
      //write to local storage so we can save progress
      //don't forget to get from local storage on load
      // sessionStorage.setItem("response", JSON.stringify(response));
    }
  };

  const handleUpdateAfterDeleteLineItem = (service, lineItem, newPricing) => {
    // update response[service][pricing][lineItem]
    let newResponse = { ...response };
    console.log(
      "handleUpdateAfterDeleteLineItem",
      service,
      lineItem,
      newResponse
    );
    delete newResponse[service].pricing[lineItem];
    console.log("handleUpdateAfterDeleteLineItem New response", newResponse);
    setResponse({ ...newResponse }); // create a new object when setting the state
  };

  const getPricingResponseForm = (service, tier) => {
    let lineItemOptions = [];
    if (service == "mailings") {
      lineItemOptions = [
        { name: "Base", value: "base_price" },
        { name: "Setup Fee", value: "setup_fee" },
        { name: "Mail Letter", value: "mail_letter" },
        { name: "Return Mail", value: "return_mail" },
        { name: "Additional Language", value: "additional_language" },
        { name: "Additional Template", value: "additional_template" },
        { name: "Address Append", value: "address_append" },
      ];
    } else if (service === "emailings") {
      lineItemOptions = [
        { name: "Base", value: "base_price" },
        { name: "Setup Fee", value: "setup_fee" },
      ];
    } else if (service === "call_center") {
      lineItemOptions = [
        { name: "Base", value: "base_price" },
        { name: "Setup Fee", value: "setup_fee" },
      ];
    } else if (service === "credit_monitoring") {
      lineItemOptions = [
        {
          name: "Credit Monitoring - Single Bureau",
          value: "single_bureau_credit_monitoring",
        },
        {
          name: "Credit Monitoring - Two Bureau",
          value: "two_bureau_credit_monitoring",
        },
        {
          name: "Credit Monitoring - Three Bureau",
          value: "three_bureau_credit_monitoring",
        },
        {
          name: "ID Theft Insurance < $100k",
          value: "id_theft_insurance_100k",
        },
        {
          name: "ID Theft Insurance up to $1M",
          value: "id_theft_insurance_1m",
        },
        {
          name: "ID Theft Insurance over $1M",
          value: "id_theft_insurance_1m_plus",
        },
        {
          name: "Dark Web Monitoring",
          value: "dark_web_monitoring",
        },
        {
          name: "Identity Restoration",
          value: "identity_restoration",
        },
      ];
    } else if (service === "data_mining") {
      lineItemOptions = [
        { name: "Encrypted Hard Drive", value: "encrypted_hard_drive" },
        { name: "Processing", value: "processing" },

        { name: "Hosting", value: "hosting" },
        {
          name: "Manual Analysis",
          value: "manual_analysis",
          showTooltip: true,
          tooltipText:
            "Use this for manual document viewing, tagging, coding, and searching.",
        },
        { name: "Automated Analysis", value: "automated_analysis" },

        {
          name: "Foreign Language Analysis",
          value: "foreign_language_analysis",
        },

        {
          name: "Project Management",
          value: "project_management",
        },
        { name: "Technical Time", value: "technical_time" },
        { name: "Engagement Closure", value: "engagement_closure" },
        { name: "User Licenses", value: "user_licenses" },
      ];
    } else if (service === "document_review") {
      lineItemOptions = [
        {
          name: "Encrypted Hard Drive",
          value: "encrypted_hard_drive",
          showTooltip: false,
          tooltipText: "If a hard drive must be decrypted, use this option.",
        },
        {
          name: "Processing",
          value: "processing",
          showTooltip: false,
          tooltipText:
            "Use this option for data ingestion and processing into a tool for analysis.",
        },

        { name: "Hosting", value: "hosting" },
        {
          name: "Basic Review",
          value: "basic_review",
          showTooltip: true,
          tooltipText:
            "Use this option for your basic review of documents. This includes tagging or coding documents, but not entity extraction.",
        },

        {
          name: "Extraction Review",
          value: "extraction_review",
          showTooltip: true,
          tooltipText:
            "Use this option for review of documents that includes manual entity extraction.",
        },
        {
          name: "Excel Review",
          value: "excel_review",
          showTooltip: true,
          tooltipText:
            "Use this option if you charge for special review of dense spreadsheets.",
        },
        {
          name: "PDF Review",
          value: "pdf_review",
          showTooltip: true,
          tooltipText:
            "Use this option if you charge for special review of PDF's.",
        },
        { name: "Foreign Language Review", value: "foreign_language" },

        {
          name: "Automated Entity Extraction",
          value: "automated_entity_extraction",
        },
        {
          name: "Manual Entity Consolidation",
          value: "manual_entity_consolidation",
        },
        {
          name: "Automated Entity Consolidation",
          value: "automated_entity_consolidation",
        },
        { name: "Quality Control", value: "quality_control" },

        {
          name: "Project Management",
          value: "project_management",
        },
        { name: "Technical Time", value: "technical_time" },
        { name: "Engagement Closure", value: "engagement_closure" },
        { name: "User Licenses", value: "user_licenses" },
      ];
    } else if (service === "forensics") {
      lineItemOptions = [];
    }

    let units = [];
    if (service == "mailings") {
      units = [
        { name: "Letter", value: "letter" },
        { name: "Page", value: "page" },
        { name: "Template", value: "template" },
        { name: "Record", value: "record" },
        {
          name: "Fixed Price",
          value: "fixed_price",
        },
      ];
    } else if (service === "emailings") {
      units = [
        { name: "Email", value: "email" },
        {
          name: "Fixed Price",
          value: "fixed_price",
        },
      ];
    } else if (service === "call_center") {
      units = [
        { name: "Call", value: "call" },
        { name: "Minute", value: "minute" },
        { name: "Person", value: "person" },
        {
          name: "Fixed Price",
          value: "fixed_price",
        },
      ];
    } else if (service === "credit_monitoring") {
      units = [
        { name: "Month", value: "month" },
        {
          name: "Year",
          value: "year",
        },
        {
          name: "Person",
          value: "person",
        },
        {
          name: "Redemption",
          value: "redemption",
        },
        {
          name: "Fixed Price",
          value: "fixed_price",
        },
      ];
    } else if (service === "data_mining") {
      units = [
        { name: "Document", value: "document" },
        { name: "Hour", value: "hour" },
        { name: "GB", value: "gb" },
        {
          name: "Fixed Price",
          value: "fixed_price",
        },
      ];
    } else if (service === "document_review") {
      units = [
        { name: "Document", value: "document" },
        { name: "Hour", value: "hour" },
        { name: "GB", value: "gb" },
        { name: "Entity", value: "entity" },
        {
          name: "Fixed Price",
          value: "fixed_price",
        },
      ];
    } else {
      units = [];
    }
    console.log("getPricingResponseForm", service, lineItemOptions, units);
    return (
      <>
        <PricingResponseForm
          id={`${service}-pricing-form`}
          setServiceDrawerOpen={setServiceDrawerOpen}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
          fullQuote={fullQuote}
          selectedTab={service}
          pricingOptions={lineItemOptions}
          unitOptions={units}
          response={response}
          field="pricing"
          handlePricingChange={(v) => {
            console.log("handlePricingChange", service, v);
          }}
          colSpan={12 - colSpan}
          handleFieldChange={handleFieldChange}
          handleUpdateAfterDeleteLineItem={(lineItem, newPricing) => {
            handleUpdateAfterDeleteLineItem(service, lineItem, newPricing);
          }}
          handleSaveServiceClick={handleSaveServiceClick}
          isLoading={isLoading}
          isSaved={isSaved}
          err={err}
          setErr={setErr}
          errMessage={errMessage}
          setErrMessage={setErrMessage}
          autosaving={autosaving}
        />
      </>
    );
  };
  const handleUploadModal = () => {
    setUploadModal(!uploadModal);
  };
  const handleHideBanner = () => {
    setShowBanner(false);
  };
  // useEffect(() => {
  //   console.log("fileList Changed in NewSolicitation", fileList);
  // }, [fileList]);

  const updateFileList = (e, file, action, documentType) => {
    console.log("updateFileList", file);
    e && e.preventDefault();

    if (action === "add") {
      let document = {
        name: file.originalname,
        size: file.size,
        type: file.mimetype,
        documentType: "Contract",
        path: file.path,
      };
      setFileList([...fileList, document]);
    } else {
      let newFileList =
        fileList &&
        fileList.length > 0 &&
        fileList.filter((el) => el.path !== file.path);
      setFileList(newFileList);
    }
  };
  return (
    <div
      id="page_container"
      className="h-full overflow-auto lg:overflow-hidden pb-6"
    >
      <Transition
        show={showBanner}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="sticky top-0 z-50 bg-white border-b border-gray-200  ">
          <NotificationBanner
            // className={"sticky top-64px"}
            setOpen={handleHideBanner}
            showClose={true}
            text={`Welcome to the new BETA vendor pricing experience. We’ve added a few new features and still have more to come. 
            Look for the Pricing Help button on each page for help entering your bids.  
            `}
            action={
              <Button
                size="xs"
                color="outline-white"
                className="ml-4"
                handleClick={() => {
                  setDrawerOpen(true);
                }}
              >
                Learn More
              </Button>
            }
          />
        </div>
      </Transition>

      <header
        id="header"
        className="flex items-center justify-between px-6
         h-12 sticky top-0 z-40 bg-white border-b border-gray-200  "
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <img className="mr-3 h-8 my-auto " src={Logo} />

            <span className="ml-0 inline-flex items-center">
              <h1 className="text-lg font-medium text-slate-900">
                Breachlink Public Vendor Portal
              </h1>
            </span>
          </div>{" "}
        </div>

        <div className="flex items-center space-x-3">
          {/* <FeatureFlag featureName="Comment on Bid Request" type="flag"> */}
          {/* {process.env.REACT_APP_NODE_ENV === "production" ? null : ( */}

          {/* </FeatureFlag> */}
          {/* <Button
                loader={isLoading("pdf")}
                color="secondary"
                handleClick={handleDownloadPdf}
              >
                {!loading && (
                  <ArrowDownTrayIcon
                    className="h-5 w-5 mr-2"
                    aria-hidden="true"
                  />
                )}
                Download PDF
              </Button> */}

          {fullQuote.status &&
            fullQuote.status.toLowerCase() == "submitted" && (
              <Button
                color="red"
                handleClick={handleCleanUpClick}
                loader={isLoading("cleanUp")}
                audit={true}
                action="Delete Bid"
                details={fullQuote}
              >
                <Icon icon="ic:delete" className="mr-2" />
                Delete Response
              </Button>
            )}
          {fullQuote.status &&
            (fullQuote.status.toLowerCase() == "submitted" ||
              fullQuote.status == "declined") && (
              <Button
                color="outline"
                handleClick={handleEditClick}
                loader={isLoading("setEdit")}
                audit={true}
                action="Edit Bid"
                details={fullQuote}
              >
                {!isLoading("setEdit") && (
                  <Icon icon="ic:edit" className="mr-2" />
                )}
                Edit Bid
              </Button>
            )}
          {!fullQuote.vendor ? (
            <span className="inline-flex items-center text-red-500 text-xs font-semibold mr-6">
              <Tooltip
                title="Supplier Information Required"
                description="Supplier Information is required in order to submit a bid or decline the request. Please complete the Supplier Information tab to continue."
              >
                <Icon icon="ion:warning" className="w-6 h-6" />
              </Tooltip>
            </span>
          ) : submitErr ? (
            <span className="inline-flex items-center text-red-500 text-xs font-semibold mr-6">
              <Tooltip
                title="Estimated Price"
                description="An estimated price is required for all services in order to submit a bid. Please check each tab to ensure that your services have an estimated price."
              >
                <Icon icon="ion:warning" className="w-6 h-6" />
              </Tooltip>
            </span>
          ) : (
            <span className="inline-flex items-center text-green-500 text-xs font-semibold"></span>
          )}
          {submissionRollbackError && (
            <span className="inline-flex items-center text-red-500 text-xs font-semibold mr-6">
              <Tooltip
                title="Error Submitting Bid"
                description={submissionRollbackErrorMessage}
              >
                <Icon icon="ion:warning" className="w-6 h-6" />
              </Tooltip>
            </span>
          )}

          {fullQuote.status &&
            fullQuote.status.toLowerCase() == "submitted" && (
              <span className="inline-flex items-center text-emerald-500 font-medium">
                {" "}
                <Icon
                  icon="ic:check"
                  className="mr-1 w-6 h-6 text-emerald-500"
                />
                Submitted{" "}
              </span>
            )}

          {fullQuote.status && fullQuote.status.toLowerCase() == "declined" && (
            <span className="inline-flex items-center text-red-500 font-medium">
              {" "}
              <Icon
                icon="ic:baseline-do-not-disturb"
                className="mr-1 w-6 h-6 text-red-500"
              />
              Declined{" "}
            </span>
          )}
          {(fullQuote.status &&
            fullQuote.status.toLowerCase() !== "submitted" &&
            fullQuote.status !== "declined") ||
          !fullQuote.status ||
          fullQuote.status == "draft" ? (
            <>
              <Button
                disabled={
                  fullQuote.status == "submitted" ||
                  fullQuote.status == "declined" ||
                  !fullQuote.vendor
                }
                audit={true}
                action="Decline Bid"
                details={fullQuote}
                color="red"
                handleClick={(e) => handleSubmitBidClick(e, "decline")}
                loader={isLoading("decline")}
              >
                {!isLoading("decline") && (
                  <Icon icon="ic:baseline-do-not-disturb" className="mr-2" />
                )}
                Decline response{" "}
              </Button>
              <Button
                disabled={
                  fullQuote.status == "submitted" ||
                  fullQuote.status == "declined" ||
                  !fullQuote.vendor ||
                  submitErr
                }
                audit={true}
                action="Submit Bid"
                details={fullQuote}
                color="secondary"
                handleClick={(e) => handleSubmitBidClick(e, "submit")}
                loader={isLoading("submit")}
              >
                {!isLoading("submit") && (
                  <Icon icon="ic:round-send" className="mr-2" />
                )}
                Submit response{" "}
              </Button>
              {fullQuote &&
                fullQuote.isEditing &&
                fullQuote.isEditing === true && (
                  <span className="inline-flex items-center text-brand-500 font-medium">
                    {" "}
                    <Icon
                      icon="ic:edit"
                      className="mr-1 w-4 h-4 text-brand-500"
                    />
                    Editing{" "}
                  </span>
                )}
            </>
          ) : null}

          {/* <FeatureFlag
                id="comment-on-bid-request"
                featureName="Comment on Bid Request"
                type="flag"
              > */}

          {/* beta badge */}
          {/* </FeatureFlag> */}
        </div>
      </header>

      <div
        id="content-container-card"
        className="h-full flex flex-col flex-1 lg:h-[calc(100vh-100px)] max-w-14xl px-6 mt-6 mx-6 py-4 bg-white shadow-lg rounded-sm border border-gray-200  "
      >
        <div
          id="content-container-card_title_row"
          className="border-b border-gray-200 pb-3 flex flex-row lg:flex-row items-center w-full justify-between "
        >
          {/* Left Side */}
          <div id="left_tab_container" className=" inline-flex items-center ">
            {tabs && tabs.length > 0 && (
              <div className="col-span-full space-y-2">
                <Tabs
                  tabs={tabs && tabs.length > 0 ? tabs : []}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  cleanUpKeys={cleanUpKeys}
                />
              </div>
            )}
          </div>
          {/* Right Side */}
          <div id="card_title_row_right">
            <CommentButton
              profile={profile}
              from="vendor"
              color="text-secondary"
              commentType="solicitation"
              solicitation={solicitation}
              handlePost={handlePostComment}
              handleLike={(e, id) => handleLike(e, id)}
              comments={comments}
              commentText={commentText}
              setCommentText={setCommentText}
              loading={commentLoading}
            />
            {/* <Button
              loader={isLoading("pdf")}
              color="secondary"
              handleClick={handleUploadModal}
              disabled={
                fullQuote.status &&
                fullQuote.status.toLowerCase() == "submitted"
              }
            >
              Upload Attachments
            </Button> */}
          </div>
        </div>

        <div
          id="content-container-card-body"
          className={`h-full py-6 divide-y lg:divide-y-0 lg:divide-x divide-gray-200 gap-x-4 gap-y-8`}
        >
          <div
            id="content-container-card-body-right"
            // style={{ maxHeight: "75vh", minHeight: "75vh" }}
            className={`pr-6 col-span-12 h-full pb-4 overflow-y-auto overflow-x-hidden 
            scrollbar-thin scrollbar-thumb-slate-600 scrollbar-track-slate-100  
            scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
          >
            {console.log("Current tab:", selectedTab)}
            {selectedTab == "emailings" && getPricingResponseForm(selectedTab)}
            {selectedTab == "mailings" && getPricingResponseForm(selectedTab)}
            {selectedTab == "call_center" &&
              getPricingResponseForm(selectedTab)}
            {selectedTab == "credit_monitoring" &&
              getPricingResponseForm(selectedTab)}
            {selectedTab == "data_mining" &&
              getPricingResponseForm(selectedTab)}
            {selectedTab == "document_review" &&
              getPricingResponseForm(selectedTab)}
            {selectedTab == "forensics" && getPricingResponseForm(selectedTab)}
            {selectedTab === "Overview" && (
              <div className="h-full space-y-6">
                {
                  //if the solicitation is a blank object, show loading
                  //otherwise show the welcome screen
                  solicitation && Object.keys(solicitation).length === 0 ? (
                    <div className="flex items-center justify-center h-96">
                      <Skeleton type="spinner" />
                    </div>
                  ) : (
                    <Welcome
                      name={location.state.name}
                      handleSetPdfModalOpen={handleSetPdfModalOpen}
                      solicitation={solicitation}
                      newProject={solicitation}
                      fullQuote={fullQuote}
                      handleCheckNDABox={() => {
                        acceptNDA();
                        // setFullQuote({
                        //   ...fullQuote,
                        //   accept_nda: !fullQuote.accept_nda,
                        // });
                      }}
                      ndaRequired={solicitation.nda_required ? true : false}
                    />
                  )
                }

                {/* <div className="text-slate-500">
               
                      Overview of the bid request will go here. This includes
                      due date and externally facing project name? Maybe we
                      should get rid of the welcome and put it here. This can
                      also include checkbox for use to indicate they have read
                      the NDA and agree to the terms. Agreeing would create the
                      initial response, check the box in the database, and
                      unlock all the tabs.
                    </div> */}
              </div>
            )}
            {selectedTab === "Attachments" && (
              <div className="space-y-6">
                {solicitation && Object.keys(solicitation).length === 0 ? (
                  <div className="flex items-center justify-center h-96">
                    <Skeleton type="spinner" />
                  </div>
                ) : (
                  <>
                    {fullQuote?.status?.toLowerCase() !== "submitted" ? (
                      <Tip>
                        Here you can upload marketing information. This is
                        optional and can be uploaded later if needed. Be sure to
                        only include PDF files under 10 MB and do not upload
                        your bid.
                      </Tip>
                    ) : (
                      <Tip>
                        You can view attachments that you've uploaded, but you
                        cannot upload additional items since your bid has been
                        submitted.
                      </Tip>
                    )}
                    {fullQuote?.status?.toLowerCase() !== "submitted" && (
                      <AttachmentComponent
                        fullQuote={fullQuote}
                        subtitle={` Attachments`}
                        updateFileList={(e, add, action) =>
                          updateAttachments(e, add, action)
                        }
                        description={`You can upload attachments later if needed.`}
                        fileList={attachments}
                        type={`bidResponseDoc`}
                      />
                    )}
                    <div>
                      <ul role="list" className="mb-6">
                        {attachments && attachments.length > 0 && (
                          <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-slate-500">
                              Attachments
                            </dt>

                            <dd className="mt-1 text-sm text-slate-900">
                              <ul
                                role="list"
                                className="divide-y divide-gray-200 rounded-md border border-gray-200"
                              >
                                {attachments &&
                                  attachments.map((attachment) => (
                                    <li
                                      key={attachment.name}
                                      onClick={() => {
                                        console.log(
                                          "Downloading attachment",
                                          attachment
                                        );
                                        handleSetPdfModalOpen(
                                          attachment,
                                          "response"
                                        );
                                      }}
                                      className="flex cursor-pointer items-center justify-between py-3 pl-3 pr-4 text-sm"
                                    >
                                      <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon
                                          className="h-5 w-5 flex-shrink-0 text-slate-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-2 w-0 flex-1 truncate">
                                          {attachment.name}
                                        </span>
                                      </div>
                                      <div className="ml-4 flex-shrink-0">
                                        <a
                                          // href={attachment.href}
                                          onClick={() => {
                                            handleSetPdfModalOpen(
                                              attachment,
                                              "response"
                                            );
                                          }}
                                          className="font-medium text-brand-600 hover:text-brand-500"
                                        >
                                          View
                                        </a>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </dd>
                          </div>
                        )}
                      </ul>
                    </div>
                  </>
                  // <Welcome
                  //   name={location.state.name}
                  //   handleSetPdfModalOpen={handleSetPdfModalOpen}
                  //   solicitation={solicitation}
                  //   newProject={solicitation}
                  //   fullQuote={fullQuote}
                  //   handleCheckNDABox={() => {
                  //     acceptNDA();
                  //     // setFullQuote({
                  //     //   ...fullQuote,
                  //     //   accept_nda: !fullQuote.accept_nda,
                  //     // });
                  //   }}
                  //   ndaRequired={solicitation.nda_required ? true : false}
                  // />
                )}

                {/* <div className="text-slate-500">
               
                      Overview of the bid request will go here. This includes
                      due date and externally facing project name? Maybe we
                      should get rid of the welcome and put it here. This can
                      also include checkbox for use to indicate they have read
                      the NDA and agree to the terms. Agreeing would create the
                      initial response, check the box in the database, and
                      unlock all the tabs.
                    </div> */}
              </div>
            )}
            {selectedTab === "Supplier Information" && (
              <SupplierInfo
                responderName={location.state.name}
                responderEmail={location.state.email}
                responderCompany={location.state.company}
                fullQuote={fullQuote}
                updateResponse={updateResponse}
                isSaved={isSaved}
                manageSaved={manageSaved}
                setSaved={setSaved}
                vendor={vendor}
              />
            )}
          </div>
        </div>

        {/* Comments*/}
      </div>

      <ModalBasic
        id="upload-modal"
        modalOpen={uploadModal}
        setModalOpen={(e) => handleUploadModal(e)}
        title={`Upload Document`}
        size={"lg"}
        scrollable
        showClose={true}
        // saveButtonText={"Save"}
        // saveButton={false}
        // saveButtonClick={saveTemplate}
        // handleCancelClick={(e) => handleShowCompareModalClick(e)}
        // showFooter={true}
      >
        {/* {console.log(fileList, "inside vendro")} */}
        <AttachmentComponent
          subtitle={` Attachments`}
          updateFileList={(e, add, action) => updateAttachments(e, add, action)}
          description={`You can upload attachments later if needed.`}
          fileList={attachments}
          type={`bidResponseDoc`}
        />
      </ModalBasic>
      <ModalBasic
        id="pdf-viewer"
        modalOpen={pdfModalOpen}
        setModalOpen={(e) => handleSetPdfModalOpen(e)}
        title={selectedFile ? selectedFile.name : null}
        size={"lg"}
        scrollable
        showClose={true}
        // saveButtonText={"Save"}
        // saveButton={false}
        // saveButtonClick={saveTemplate}
        // handleCancelClick={(e) => handleShowCompareModalClick(e)}
        // showFooter={true}
      >
        {pdfModalOpen &&
          (!loadFile ? (
            <Skeleton type="loader" />
          ) : (
            <PdfViewer
              file={selectedFile ? selectedFile : {}}
              filename={selectedFileName ? selectedFileName : "Test"}
              url={selectedFileBlob ? selectedFileBlob : ""}
              selectedFileName={selectedFileName ? selectedFileName : "Test"}
              // file={selectedFile ? selectedFile : {}}
            />
          ))}
      </ModalBasic>
      <Drawer
        width="2/3"
        anchor="bottom"
        title={`Details for  ${utils.cleanUpKeys(selectedTab)}`}
        show={serviceDrawerOpen}
        setShow={() => setServiceDrawerOpen(false)}
      >
        {" "}
        <div
          className="h-full    col-span-full px-0 pt-3 pb-20 sm:px-0 
              overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
              scrollbar-thumb-rounded-full scrollbar-track-rounded-full
              "
        >
          <div className="flex px-6 font-semibold"></div>

          <div className="block px-6">
            {solicitation &&
              solicitation.services &&
              solicitation.services.map((service) => {
                if (
                  selectedTab === "credit_monitoring" &&
                  service.service == "credit_monitoring"
                ) {
                  if (service.service == "credit_monitoring") {
                    return (
                      <div>
                        <Tip>
                          Credit monitoring is broken into groups. Each group
                          contains different set of requirements.
                        </Tip>

                        {service.opts2 &&
                          Object.entries(service.opts2).map(
                            ([group, entries], index) => {
                              return (
                                <div>
                                  <CreditMonitoringInfo
                                    create={false}
                                    newProject={solicitation}
                                    project={entries}
                                    service={"credit_monitoring"}
                                    onFieldChange={null}
                                    handleDeleteCohort={null}
                                    groupIndex={index}
                                    cohort={entries}
                                    handleDeselectedLanguage={null}
                                    handleSelectedLanguage={null}
                                    handleClearAllSelections={null}
                                  />
                                  <div className="w-full mt-12 mb-6 pr-6 border-t border-primary-300 " />
                                </div>
                              );
                            }
                          )}
                      </div>
                    );
                  }
                } else if (
                  selectedTab === "emailings" &&
                  service.service === "emailings"
                ) {
                  return (
                    <div>
                      <div className="w-3/4">
                        <Label className="mb-2">Jurisdictions</Label>
                        <p className="text-slate-500 mb-3">
                          This section shows the total number of affected
                          individuals in each jurisdiction.
                        </p>
                        <JurisdictionInput
                          create={false}
                          scope="solicitation"
                          field={"jurisdiction"}
                          service={"emailings"}
                          newProject={solicitation}
                          project={solicitation}
                          onFieldChange={null}
                          selectedJurisdictions={
                            solicitation &&
                            solicitation.services[
                              solicitation.services.findIndex(
                                (el) => el.service === "emailings"
                              )
                            ].options &&
                            solicitation.services[
                              solicitation.services.findIndex(
                                (el) => el.service === "emailings"
                              )
                            ].options[0].jurisdictions
                              ? solicitation.services[
                                  solicitation.services.findIndex(
                                    (el) => el.service === "emailings"
                                  )
                                ].options[0].jurisdictions
                              : []
                          }
                          setSelectedJurisdictions={null}
                        />
                      </div>
                      <EmailingInfo
                        create={false}
                        scope="solicitation"
                        service={"emailings"}
                        newProject={solicitation}
                        project={solicitation}
                        onFieldChange={null}
                        onSelectionChange={null}
                        languageOptions={null}
                        handleDeselectedLanguage={null}
                        handleSelectedLanguage={null}
                        handleClearAllSelections={null}
                      />
                    </div>
                  );
                } else if (
                  selectedTab === "mailings" &&
                  service.service === "mailings"
                ) {
                  return (
                    <div>
                      <div className="w-3/4">
                        <Label className="mb-2">Jurisdictions</Label>
                        <p className="text-slate-500 mb-3">
                          This section shows the total number of affected
                          individuals in each jurisdiction.
                        </p>
                        <JurisdictionInput
                          create={false}
                          scope="solicitation"
                          field={"jurisdiction"}
                          service={"mailings"}
                          newProject={solicitation}
                          project={solicitation}
                          onFieldChange={null}
                          selectedJurisdictions={
                            solicitation &&
                            solicitation.services[
                              solicitation.services.findIndex(
                                (el) => el.service === "mailings"
                              )
                            ].options &&
                            solicitation.services[
                              solicitation.services.findIndex(
                                (el) => el.service === "mailings"
                              )
                            ].options[0].jurisdictions
                              ? solicitation.services[
                                  solicitation.services.findIndex(
                                    (el) => el.service === "mailings"
                                  )
                                ].options[0].jurisdictions
                              : []
                          }
                          setSelectedJurisdictions={null}
                        />
                      </div>

                      <Assumptions
                        service={"mailings"}
                        solicitation={solicitation}
                      />
                      <div>
                        <MailingInfo
                          create={false}
                          scope="solicitation"
                          service={"mailings"}
                          newProject={solicitation}
                          project={solicitation}
                          onFieldChange={null}
                          onSelectionChange={null}
                          languageOptions={null}
                          handleDeselectedLanguage={null}
                          handleSelectedLanguage={null}
                          handleClearAllSelections={null}
                        />
                      </div>
                    </div>
                  );
                } else if (
                  selectedTab === "call_center" &&
                  service.service === "call_center"
                ) {
                  return (
                    <div>
                      <CallCenterInfo
                        create={false}
                        scope="solicitation"
                        service={"call_center"}
                        newProject={solicitation}
                        project={solicitation}
                        onFieldChange={null}
                        onSelectionChange={null}
                        languageOptions={null}
                        handleDeselectedLanguage={null}
                        handleSelectedLanguage={null}
                        handleClearAllSelections={null}
                      />
                    </div>
                  );
                } else if (
                  selectedTab === "data_mining" &&
                  service.service === "data_mining"
                ) {
                  return (
                    <div>
                      <Assumptions
                        service={"data_mining"}
                        solicitation={solicitation}
                      />
                      <DataMiningInfo
                        create={false}
                        scope="solicitation"
                        service={"data_mining"}
                        newProject={solicitation}
                        project={solicitation}
                        onFieldChange={null}
                        onSelectionChange={null}
                        handleSelect={null}
                        handleSingleSelect={null}
                        languageOptions={null}
                        callCenterLocationOptions={null}
                        handleDeselectedLanguage={null}
                        handleMultiSelect={null}
                        handleSelectedLanguage={null}
                        handleClearAllSelections={null}
                      />
                    </div>
                  );
                } else if (
                  selectedTab === "document_review" &&
                  service.service === "document_review"
                ) {
                  return (
                    <div>
                      <Assumptions
                        service={"document_review"}
                        solicitation={solicitation}
                      />
                      <DataMiningInfo
                        create={false}
                        scope="solicitation"
                        service={"document_review"}
                        newProject={solicitation}
                        project={solicitation}
                        onFieldChange={null}
                        onSelectionChange={null}
                        languageOptions={null}
                        handleDeselectedLanguage={null}
                        handleSelectedLanguage={null}
                        handleClearAllSelections={null}
                      />
                    </div>
                  );
                } else if (
                  selectedTab === "class_action" &&
                  service.service === "class_action"
                ) {
                  return (
                    <div>
                      {/* <div className="w-3/4">
                        <Label className="mb-2">Jurisdictions</Label>
                      </div>

                      <Assumptions
                        service={"class_action"}
                        solicitation={solicitation}
                      /> */}
                      <div>
                        <ClassActionInfo
                          create={false}
                          scope="solicitation"
                          service={"class_action"}
                          newProject={solicitation}
                          project={solicitation}
                          onFieldChange={null}
                          onSelectionChange={null}
                          languageOptions={null}
                          handleDeselectedLanguage={null}
                          handleSelectedLanguage={null}
                          handleClearAllSelections={null}
                        />
                      </div>
                    </div>
                  );
                } else if (
                  selectedTab === "forensics" &&
                  service.service === "forensics"
                ) {
                  return (
                    <div>
                      <ForensicsInfo
                        create={false}
                        scope="solicitation"
                        service={"forensics"}
                        newProject={solicitation}
                        project={solicitation}
                        onFieldChange={null}
                        onSelectionChange={null}
                        languageOptions={null}
                        handleDeselectedLanguage={null}
                        handleSelectedLanguage={null}
                        handleClearAllSelections={null}
                      />
                    </div>
                  );
                } else if (
                  selectedTab === "landing_page" &&
                  service.service === "landing_page"
                ) {
                  return null;
                }
              })}
          </div>
        </div>
      </Drawer>
      <Drawer show={drawerOpen} setShow={setDrawerOpen} title="Pricing Help">
        <div className="flex px-6 mx-auto my-auto h-full">
          <HelpItems />
        </div>
      </Drawer>
      {/* <DialogBox
        open={err && err == true ? true : false}
        handleConfirmClick={() => {
          setErr(false);
        }}
        handleCancelClick={() => {
          setErr(false);
        }}
        // loading={false}
        // success={!err}
        title="An error occurred"
        message={
          "We sent your bid, but there was an error while doing so. We've emailed the support team to let them know."
        }
        OKbuttonText={"OK"}
        CancelButton={null}
      /> */}
    </div>
    //{" "}
  );
}

export default VendorResponsePage;
