import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import RightActionArea from "../../../components/ActionAreaRight";
import Button from "../../../elements/Button";
import { Icon } from "@iconify/react";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import Tooltip from "../../../components/Tooltip";
import QuestionToolTip from "../../../components/QuestionToolTip";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import Tip from "../../../components/Tip";
import { Dropdown, RadioGroupSingle, Section, TextInput } from "./DataEntry";

export default function CreditMonitoringInfo({
  create,
  readOnly,
  newProject,
  groupIndex,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleDeselectedLanguage,
  handleClearAllSelections,
  handleDeleteCohort,
  service,
  cohort,
  languageOptions,
  callCenterLocationOptions,
  handleSelect,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,

  scope,
  isChecked,
  handleMultiSelect,
}) {
  // const [options, setOptions] = useState([]);
  // const [data, setData] = useState([]);
  const [opts2, setOpts2] = useState({});
  const [jurisdictionOptions, setJurisdictionoptions] = useState([]);
  const [overrides, setOverrides] = useState([]);

  useEffect(() => {
    create && create == false ? (create = false) : (create = true);
    create == true ? (readOnly = false) : (readOnly = true);
  }, [create]);

  useEffect(() => {
    console.log("Credit Mon Overrides changed", overrides);
  }, [overrides]);

  // useEffect(() => {
  //   setData(newProject);
  // }, [newProject]);

  // useEffect(() => {
  //   console.log(
  //     "Credit Monitoring project updatedddd",
  //     JSON.stringify(newProject)
  //   );
  // }, [newProject]);

  useMemo(() => {
    let jurisdictionOptions = [];
    project &&
      project.jurisdictions &&
      project.jurisdictions.map((service) => {
        jurisdictionOptions.push({ name: service.key });
      });

    setJurisdictionoptions(jurisdictionOptions);
    console.log("Solication JD Options", project, jurisdictionOptions);
  }, []);

  // useEffect(() => {
  //   if (
  //     newProject &&
  //     newProject.services &&
  //     newProject.services[
  //       newProject.services.findIndex((el) => el.service === service)
  //     ].opts2
  //   ) {
  //     setOptions(
  //       newProject.services[
  //         newProject.services.findIndex((el) => el.service === service)
  //       ].opts2
  //     );
  //   } else {
  //     console.log("UNDEFINED");
  //   }
  // }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      let opts2 =
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2;
      setOpts2(opts2);
    }
    console.log("Setting OPTS2 when newProject changed", opts2);
    console.log("NewProject Changed", newProject);
  }, [newProject]);

  const deleteCohort = (e, groupIndex, groupName) => {
    console.log("handleDeleteCohort", e, groupIndex, groupName);
    e.preventDefault();
    handleDeleteCohort(groupIndex, groupName);
    removeAllOverridesForGroup(groupName);
  };
  const addOverride = (field, group, usingDefault) => {
    if (usingDefault) return;
    // e.preventDefault();
    console.log("addOverride", field, group);
    if (checkOverride(field, group)) {
      return;
    }
    let newOverrides = [...overrides];
    newOverrides.push({ field: field, group: group });
    setOverrides(newOverrides);
  };
  const removeAllOverridesForGroup = (group) => {
    console.log("removeAllOverridesForGroup", group);
    let newOverrides = [...overrides];
    newOverrides = newOverrides.filter((override) => override.group !== group);
    setOverrides(newOverrides);
  };

  const removeOverride = (field, group) => {
    console.log("removeOverride", field, group);
    let newOverrides = [...overrides];
    newOverrides = newOverrides.filter(
      (override) => override.field !== field && override.group !== group
    );
    setOverrides(newOverrides);
  };

  const checkOverride = (field, group) => {
    console.log("chkOverride", field, group);
    let chk = false;
    overrides.forEach((override) => {
      if (override.field === field && override.group === group) {
        chk = true;
      }
    });
    return chk;
  };

  const handleClearChildSelections = (
    value,
    service,
    field,
    group,
    selectType,
    dependencyValue
  ) => {
    //specifically handle custom_pricing
    if (field === "pricing_options") {
      let e = {
        target: {
          name: "custom_pricing",
          value: "",
        },
      };
      if (!value.includes("Other")) {
        onFieldChange(
          "options",
          e,
          "custom_pricing",
          "credit_monitoring",
          group
        );
      }
    }
  };

  return (
    <div className="">
      <div className="pb-6 flex items-center justify-between">
        {create && (
          <h3 className="inline-flex text-base font-semibold leading-6 text-slate-900">
            Group {groupIndex + 1}
          </h3>
        )}
        <RightActionArea>
          {create && (
            <Button
              iconButton
              handleClick={(e) =>
                deleteCohort(e, groupIndex, "group_" + groupIndex + 1)
              }
            >
              <TrashIcon className="h-5 w-5" />
            </Button>
          )}
        </RightActionArea>
      </div>
      <div>
        {create && (
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Basic information
          </h3>
        )}
        {create ? (
          <Paragraph>
            Use the form below to specify your needs for this specific group of
            individuals. When you're done, you can create a new group.
          </Paragraph>
        ) : (
          ""
        )}
      </div>
      {create && create == true && (
        <div className=" col-span-full bg-[#f6f8ff] border border-brand-200 rounded p-2 pb-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <Section
            label="Assumptions"
            // description={`${
            //   create
            //     ? "These fields are required, and will help ensure a more consistent bidding process. "
            //     : "These are the assumptions that were made when the project was created. Please use these assumptions when creating your quotes."
            // }`}
            create={create}
          />
          <Tip colSpan={"full"} noMargin>
            <div className="inline">
              The fields in the assumptions section are required. It is
              extremely important to fill out the Assumptions section to get
              simple, accurate quotes.
              <span className="ml-1 font-medium">
                We've provided you with some default answers.
              </span>
            </div>
          </Tip>
          <div id="hidden-assumptions" className="col-span-full"></div>
          <div id="assumption-list" className="col-span-full space-y-10">
            {create &&
              newProject.number_of_affected_individuals &&
              newProject.number_of_affected_individuals !== "" && (
                <div className="col-span-full">
                  You indicated {newProject.number_of_affected_individuals}{" "}
                  affected individuals when creating this bid request.
                </div>
              )}

            <TextInput
              label="Number of Affected Individuals"
              tooltip={{
                title: "Affected Individuals",
                text: "Letting the vendor know how many people were affected will help them with the assumption of how many calls they will need to handle.",
              }}
              service={service}
              field="number_of_affected_individuals"
              // type="size"
              defaultValue={
                !checkOverride("number_of_affected_individuals") &&
                newProject.number_of_affected_individuals
                  ? newProject.number_of_affected_individuals
                  : null
              }
              create={create}
              opts2={
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2["group_" + groupIndex + 1]
              }
              placeholder=""
              onFieldChange={(options, e, field, service, usingDefault) => {
                addOverride(field, "group_" + groupIndex + 1, usingDefault);
                console.log("onFieldChange", options, e, field, service);
                onFieldChange(
                  options,
                  e,
                  field,
                  service,
                  "group_" + groupIndex + 1
                );
              }}
              required
              group={"group_" + groupIndex + 1}
            />
            <TextInput
              label="Adult Credit Monitoring"
              subLabel="How many in this group require adult credit monitoring?"
              tooltip={{
                title: "Affected Individuals",
                text: "Letting the vendor know how many people were affected will help them with the assumption of how many calls they will need to handle.",
              }}
              service={service}
              field="adults"
              // type="size"
              create={create}
              opts2={
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2["group_" + groupIndex + 1]
              }
              placeholder=""
              onFieldChange={(options, e, field, service) => {
                console.log("onFieldChange", options, e, field, service);
                onFieldChange(
                  options,
                  e,
                  field,
                  service,
                  "group_" + groupIndex + 1
                );
              }}
              required
              group={"group_" + groupIndex + 1}
            />
            <TextInput
              label="Minor Credit Monitoring"
              subLabel="How many in this group require credit monitoring for minors?"
              tooltip={{
                title: "Affected Individuals",
                text: "Letting the vendor know how many people were affected will help them with the assumption of how many calls they will need to handle.",
              }}
              service={service}
              field="minors"
              // type="size"
              create={create}
              // defaultValue={"12"}
              opts2={
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2["group_" + groupIndex + 1]
              }
              placeholder=""
              onFieldChange={(options, e, field, service) => {
                console.log("onFieldChange", options, e, field, service);
                onFieldChange(
                  options,
                  e,
                  field,
                  service,
                  "group_" + groupIndex + 1
                );
              }}
              required
              group={"group_" + groupIndex + 1}
            />
            <TextInput
              label="Jurisdiction"
              subLabel="What country do the affected individuals live in?"
              tooltip={{
                title: "Affected Individuals",
                text: "Letting the vendor know how many people were affected will help them with the assumption of how many calls they will need to handle.",
              }}
              service={service}
              field="jurisdiction"
              // type="size"
              create={create}
              opts2={
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2["group_" + groupIndex + 1]
              }
              placeholder=""
              onFieldChange={(options, e, field, service) => {
                console.log(
                  "onFieldChange juisdiction",
                  options,
                  e,
                  field,
                  service
                );
                onFieldChange(
                  options,
                  e,
                  field,
                  service,
                  "group_" + groupIndex + 1
                );
              }}
              required
              group={"group_" + groupIndex + 1}
            />
            <RadioGroupSingle
              cols={3}
              rows={2}
              type="multiSelect"
              label="Number of Credit Bureaus"
              description="How many credit bureaus would you like to offer? (select one or more)"
              tooltip={{
                title: "Call Center Coverage",
                text: "Each vendor will provide their hours of operation for the days of the week you require coverage.  Be sure to check these hours to ensure they fit your.",
              }}
              service={service}
              field="number_of_bureaus"
              create={create}
              opts2={opts2["group_" + groupIndex + 1] || cohort}
              using={opts2["group_" + groupIndex + 1] ? "group" : "cohort"}
              placeholder=""
              // fieldType="single"
              open={true}
              newProject={newProject}
              // handleSelect={handleMultiSelect}
              // handleSelect={() => {
              //   console.log("handleSelect");
              // }}
              handleSelect={(e, service, field, group, fieldType) => {
                console.log(
                  "RadioGroupSingle handleMultiSelect",
                  e,
                  service,
                  field,
                  group,
                  fieldType
                );
                handleMultiSelect(
                  e,
                  service,
                  field,

                  "group_" + groupIndex + 1,
                  "multiSelect"
                );
              }}
              defaultValue={["1"]}
              group={"group_" + groupIndex + 1}
              options={[
                {
                  id: 1,
                  title: "1",
                  description: "",
                  value: "1",
                },
                {
                  id: 2,
                  title: "2",
                  description: "",
                  value: "2",
                },
                {
                  id: 3,
                  title: "3",
                  description: "",
                  value: "3",
                },
              ]}
            />
            <RadioGroupSingle
              cols={5}
              rows={1}
              type="multiSelect"
              label="Years of Credit Monitoring"
              description="How many years of credit monitoring would you like to offer? (select one or more)"
              tooltip={{
                title: "Call Center Coverage",
                text: "Each vendor will provide their hours of operation for the days of the week you require coverage.  Be sure to check these hours to ensure they fit your.",
              }}
              service={service}
              field="years_of_credit_monitoring"
              create={create}
              opts2={opts2["group_" + groupIndex + 1] || cohort}
              using={opts2["group_" + groupIndex + 1] ? "group" : "cohort"}
              placeholder=""
              // fieldType="single"
              open={true}
              newProject={newProject}
              // handleSelect={handleMultiSelect}
              // handleSelect={() => {
              //   console.log("handleSelect");
              // }}
              handleSelect={(e, service, field, group, fieldType) => {
                console.log(
                  "RadioGroupSingle handleMultiSelect",
                  e,
                  service,
                  field,
                  group,
                  fieldType
                );
                handleMultiSelect(
                  e,
                  service,
                  field,

                  "group_" + groupIndex + 1,
                  "multiSelect"
                );
              }}
              defaultValue={["1"]}
              group={"group_" + groupIndex + 1}
              options={[
                {
                  id: 1,
                  title: "1",
                  description: "",
                  value: "1",
                },
                {
                  id: 2,
                  title: "2",
                  description: "",
                  value: "2",
                },
                {
                  id: 3,
                  title: "3",
                  description: "",
                  value: "3",
                },
                {
                  id: 4,
                  title: "4",
                  description: "",
                  value: "4",
                },
                {
                  id: 5,
                  title: "5",
                  description: "",
                  value: "5",
                },
              ]}
            />
            <RadioGroupSingle
              cols={4}
              rows={2}
              type="multiSelect"
              label="Pricing Options"
              description="What type of pricing would you like? (select one or more)"
              tooltip={{
                title: "Call Center Coverage",
                text: "Each vendor will provide their hours of operation for the days of the week you require coverage.  Be sure to check these hours to ensure they fit your.",
              }}
              service={service}
              field="pricing_type"
              create={create}
              opts2={opts2["group_" + groupIndex + 1] || cohort}
              using={opts2["group_" + groupIndex + 1] ? "group" : "cohort"}
              placeholder=""
              // fieldType="single"
              open={true}
              newProject={newProject}
              handleSelect={(e, service, field, group, fieldType) => {
                console.log(
                  "RadioGroupSingle handleMultiSelect",
                  e,
                  service,
                  field,
                  group,
                  fieldType
                );
                handleMultiSelect(
                  e,
                  service,
                  field,

                  "group_" + groupIndex + 1,
                  "multiSelect"
                );
              }}
              defaultValue={["fixed_fee", "redemption"]}
              group={"group_" + groupIndex + 1}
              options={[
                {
                  id: 1,
                  title: "Fixed Fee",
                  description: "",
                  value: "fixed_fee",
                },
                {
                  id: 2,
                  title: "Redemption",
                  description: "",
                  value: "redemption",
                },
              ]}
            />
            <RadioGroupSingle
              cols={4}
              rows={2}
              type="multiSelect"
              label="Required Language"
              description="What languages do you require credit monitoring agents to speak?"
              tooltip={{
                title: "Required Language",
                text: "Select the languages you require the credit monitoring agents to speak.",
              }}
              service={service}
              field="required_language"
              create={create}
              opts2={opts2["group_" + groupIndex + 1] || cohort}
              using={opts2["group_" + groupIndex + 1] ? "group" : "cohort"}
              placeholder=""
              // fieldType="single"
              open={true}
              newProject={newProject}
              handleSelect={(e, service, field, group, fieldType) => {
                console.log(
                  "RadioGroupSingle handleMultiSelect",
                  e,
                  service,
                  field,
                  group,
                  fieldType
                );
                handleMultiSelect(
                  e,
                  service,
                  field,

                  "group_" + groupIndex + 1,
                  "multiSelect"
                );
              }}
              defaultValue={["English"]}
              group={"group_" + groupIndex + 1}
              options={[
                {
                  id: 1,
                  title: "English",
                  description: "",
                  value: "English",
                },
                {
                  id: 2,
                  title: "Spanish",
                  description: "",
                  value: "Spanish",
                },
                {
                  id: 3,
                  title: "French",
                  description: "",
                  value: "French",
                },
                {
                  id: 4,
                  title: "Other",
                  description: "Specify below",
                  value: "Other",
                },
              ]}
            />
            {/* <Dropdown
              label="Required Languages"
              subLabel="What languages do you require to be spoken by call center staff?"
              tooltip={{
                title: "Call Center Languages",
                text: "Staffing call centers with agents who speak multiple languages can be costly, so it is important to make this part of your request so that all vendors are consistent in their bidding..",
              }}
              service={service}
              field="required_language"
              create={create}
              opts2={opts2["group_" + groupIndex + 1] || cohort}
              placeholder=""
              fieldType="multiSelect"
              open={true}
              defaultValue={["English"]}
              newProject={newProject}
              handleSelect={(e) => {
                handleSelectedLanguage(
                  e,
                  "credit_monitoring",
                  "required_language",

                  "group_" + groupIndex + 1,
                  "multiSelect"
                );
              }}
              handleDeselect={handleDeselectedLanguage}
              handleClearAllSelections={(v) =>
                handleClearAllSelections(
                  v,
                  "credit_monitoring",
                  "required_language"
                )
              } //{handleClearAllSelections}
              options={languageOptions ? languageOptions : []}
            /> */}
          </div>
        </div>
      )}

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-6">
        {/* Required Languages */}
        {/* <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="call_center_location"
            className="my-auto col-span-4 block "
          >
            What languages do you require credit bureau agents to speak?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"chips"}
                // chipColor="slate"
                answer={
                  cohort && cohort.required_language
                    ? cohort.required_language
                    : "English"
                }
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="required_langage"
                options={[
                  { name: "English" },
                  { name: "French" },
                  { name: "Spanish" },
                ]}
                fieldType="multiSelect"
                open={true}
                handleSelect={(e) => {
                  handleSelectedLanguage(
                    e,
                    "credit_monitoring",
                    "required_language",

                    "group_" + groupIndex + 1,
                    "multiSelect"
                  );
                }}
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(v) =>
                  handleClearAllSelections(
                    v,
                    "credit_monitoring",
                    "required_language"
                  )
                } //{handleClearAllSelections}
                // value={
                //   opts2["group_" + groupIndex + 1] &&
                //   opts2["group_" + groupIndex + 1].required_language
                //     ? opts2["group_" + groupIndex + 1].required_language
                //     : ""
                // }
                // value={() => {
                //   return (
                //     (newProject &&
                //       newProject.services[
                //         newProject.services.findIndex(
                //           (el) => el.service === service
                //         )
                //       ].opts2["group_" + groupIndex + 1].required_language) ||
                //     []
                //   );
                // }}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1] &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1].required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].required_language
                    : [];
                }}
              />
            </div>
          )}
        </div> */}
        {/* MULTIPLE: Additional Services */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="call_center_location"
            className="my-auto col-span-4 block "
          >
            What additional features should the service include?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"chips"}
                answer={
                  cohort && cohort.additional_services
                    ? cohort.additional_services
                    : "No additional services required"
                }
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="additional_services"
                options={[
                  { name: "ID Theft Insurance" },
                  { name: "ID Restoration" },
                  { name: "Dark Web monitoring" },
                ]}
                fieldType="multiSelect"
                open={true}
                handleSelect={(e) =>
                  handleSelectedLanguage(
                    e,
                    "credit_monitoring",
                    "additional_services",

                    "group_" + groupIndex + 1,
                    "multiSelect"
                  )
                }
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(
                    value,
                    "credit_monitoring",
                    "additional_services"
                  )
                } //{handleClearAllSelections}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1] &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1].additional_services
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].additional_services
                    : [];
                }}

                // value={
                //   opts2["group_" + groupIndex + 1] &&
                //   opts2["group_" + groupIndex + 1].additional_services
                //     ? opts2["group_" + groupIndex + 1].additional_services
                //     : ""
                // }
              />
            </div>
          )}
        </div>
        {/* SINGLE: INSURANCE OPTIONS */}
        {opts2["group_" + groupIndex + 1] &&
          opts2["group_" + groupIndex + 1].additional_services &&
          opts2["group_" + groupIndex + 1].additional_services.includes(
            "ID Theft Insurance"
          ) && (
            <div className="sm:col-span-6 grid grid-cols-6">
              <Label
                sm
                bold
                htmlFor="call_center_location"
                className="my-auto col-span-4 block "
              >
                Which identity theft insurance coverage would you like to offer?
              </Label>
              {!create && (
                <div className="col-span-full mb-6">
                  <ReadOnlyAnswer
                    type={"chips"}
                    answer={
                      cohort && cohort.insurance_options
                        ? cohort.insurance_options
                        : "No insurance required"
                    }
                  />
                </div>
              )}

              {create && (
                <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
                  <ComboboxSelector
                    name="insurance_options"
                    options={[
                      { name: "Less than $100K" },
                      { name: "Between $100k and $1 million" },
                      { name: "Greater than $1 million" },
                    ]}
                    fieldType="single"
                    open={true}
                    handleSelect={(e) => {
                      handleSelectedLanguage(
                        e,
                        "credit_monitoring",
                        "insurance_options",

                        "group_" + groupIndex + 1,
                        "single"
                      );
                    }}
                    handleDeselect={handleDeselectedLanguage}
                    handleClearAllSelections={(value) =>
                      handleClearAllSelections(
                        value,
                        "credit_monitoring",
                        "insurance_options"
                      )
                    } //{handleClearAllSelections}
                    value={
                      opts2["group_" + groupIndex + 1] &&
                      opts2["group_" + groupIndex + 1].insurance_options
                        ? opts2["group_" + groupIndex + 1].insurance_options
                        : ""
                    }
                  />
                </div>
              )}
            </div>
          )}
        {/* SINGLE: CREDIT RESTORATION OPTIONS */}
        {/* {opts2["group_" + groupIndex + 1] &&
          opts2["group_" + groupIndex + 1].additional_services &&
          opts2["group_" + groupIndex + 1].additional_services.includes(
            "ID Restoration"
          ) && (
            <div className="sm:col-span-6 grid grid-cols-6">
              <Label
                sm
                bold
                htmlFor="call_center_location"
                className="my-auto col-span-4 block "
              >
                Which credit restoration options would you like to provide?
              </Label>

              <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
                <ComboboxSelector
                  name="credit_resoration_options"
                  options={[
                    { name: "Basic" },
                    { name: "White Glove" },
                    { name: "I don't care" },
                    //cheapest? most comprehensive?
                  ]}
                  fieldType="single"
                  open={true}
                  handleSelect={(e) => {
                    handleSelectedLanguage(
                      e,
                      "credit_monitoring",
                      "credit_restoration_options",

                      "group_" + groupIndex + 1,
                      "single"
                    );
                  }}
                  handleDeselect={handleDeselectedLanguage}
                  handleClearAllSelections={(value) =>
                    handleClearAllSelections(
                      value,
                      "credit_monitoring",
                      "credit_restoration_options"
                    )
                  } //{handleClearAllSelections}
                  value={
                    opts2["group_" + groupIndex + 1] &&
                    opts2["group_" + groupIndex + 1].credit_restoration_options
                      ? opts2["group_" + groupIndex + 1]
                          .credit_restoration_options
                      : ""
                  }
                />
              </div>
            </div>
          )} */}
        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          {create && (
            <p className="mt-2 text-base text-slate-500">
              Use the below field to provide any additional details about your
              project or bid request that you think might be helpful to the
              vendor.
            </p>
          )}
          <div className="mt-1">
            {create ? (
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2["group_" + groupIndex + 1] &&
                  opts2["group_" + groupIndex + 1].additional_details
                    ? opts2["group_" + groupIndex + 1].additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    "credit_monitoring",

                    "group_" + groupIndex + 1
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-base"
              />
            ) : (
              <ReadOnlyAnswer
                type={"text"}
                answer={
                  opts2["group_" + groupIndex + 1] &&
                  opts2["group_" + groupIndex + 1].additional_details
                    ? opts2["group_" + groupIndex + 1].additional_details
                    : "No answer given"
                }
              ></ReadOnlyAnswer>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
