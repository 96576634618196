import { PlusIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import ListBoxSelector from "./ListBoxSelector";
import MultiSelectListBox from "./MultiSelectListBox";
import ComboboxSelector from "./ComboBoxSelector";

export default function AddToListComponent({ listItems, updateList }, props) {
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(listItems);
  }, [listItems]);

  const handleUpdateList = (e, value, action) => {
    let oldList = [...list];
    let newlist = [];
    console.log("OLD LIST", oldList);
    //if action is "remove", remove it from oldList
    //if action is "add", add it to oldList
    //setList(oldList)
    if (action == "remove") {
      //remove value from oldList
      newlist = oldList.filter((v) => v !== value);
      setList(newlist);
      console.log("Remove", value, newlist);
    } else {
      console.log("Add", value, newlist);
      oldList.push(value);
      newlist = oldList;
      setList(newlist);
    }
    console.log("Modified List", newlist);
    updateList(newlist);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex"></div>
        <div className="flex"></div>
      </div>
      <div className="flex">
        {
          <ul>
            <div className="flex flex-col space-y-1">
              {list.map((a) => {
                console.log("This is as service", a);
                return (
                  <li className="flex justify-between">
                    <div className="flex space-x-2">
                      {/* <PaperClipIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    /> */}
                      <p>{a}</p>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={(e) => handleUpdateList(e, a, "remove")}
                        className="text-red-500 hover:text-red-700"
                      >
                        <XMarkIcon
                          className="ml-3 h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </li>
                );
              })}
            </div>
          </ul>
        }
      </div>
      <div className="flex py-4 font-semibold items-center text-brand-500">
        <ComboboxSelector
          textbutton
          id="services"
          name="services"
          fieldType="multiSelect"
          open={true}
          handleSelect={(v) => {
            handleUpdateList(null, v, "add");
          }}
          handleDeselect={(v) => {
            handleUpdateList(null, v, "remove");
          }}
          handleClearAllSelections={() => setList([])} //{handleClearAllSelections}
          value={list}
          options={[
            {
              id: 5,
              name: "Data Mining",
              value: "Data Mining",
            },
            {
              id: 6,
              name: "Document Review",
              value: "Document Review",
            },
            { id: "1", name: "Mailing", value: "Mailing" },
            {
              id: 2,
              name: "Emailing",
              value: "Emailing",
            },
            {
              id: 3,
              name: "Call Center",
              value: "Call Center",
            },
            {
              id: 4,
              name: "Credit Monitoring",
              value: "Credit Monitoring",
            },
            {
              id: 7,
              name: "Incident Response",
              value: "Incident Response",
            },

            // {
            //   id: 7,
            //   name: "Forensics",
            //   value: "Forensics",
            // },
            // {
            //   id: 8,
            //   name: "Ransomware",
            //   value: "Ransomware",
            // },
            {
              id: 9,
              name: "Breach Counsel",
              value: "Breach Counsel",
            },
            {
              id: 10,
              name: "Public Relations",
              value: "Public Relations",
            },
            {
              id: 11,
              name: "Cyber Insurance",
              value: "Cyber Insurance",
            },
            {
              id: 12,
              name: "eDiscovery Document Review",
              value: "eDiscovery Document Review",
            },
            {
              id: 13,
              name: "Class Action",
              value: "Class Action",
            },
          ].sort((a, b) => (a.name > b.name ? 1 : -1))}
        />
      </div>
    </div>
  );
}
