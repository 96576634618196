import React, { useEffect, useState } from "react";
import Drawer from "../Drawer";
import DropdownMenu from "../DropdownMenu";
import Skeleton from "../Skeleton";

const ChatComponent = ({
  show,
  setShow,
  title,
  expandable,
  showFooter,
  scrollable,
  footerContent,
  initialConversation,
  conversationId,
  questionChoices,

  chatType,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [conversation, setConversation] = useState(initialConversation);

  const [gettingAnswer, setGettingAnswer] = useState(false);
  const [questionsForClaire, setQuestionsForClaire] = useState(questionChoices);

  useEffect(() => {
    setShowDrawer(show);
  }, [show]);

  useEffect(() => {
    //remove prompts from questionsForClaire
    //if they arein the conversatin
    let newQuestions = questionsForClaire.filter((question) => {
      let found = conversation.find((item) => {
        return item.promptId == question.id;
      });
      return !found;
    });
    setQuestionsForClaire(newQuestions);
  }, [conversation]);

  useEffect(() => {
    executeScroll("conversation-end");
  }, [conversation]);

  const executeScroll = (e) => {
    // alert("scrolling to " + e);
    console.log("scrolling to ", e);
    const element = document.getElementById("last-message");
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  };

  const handleSetShow = (value) => {
    setShowDrawer(value);
    setShow(value);
  };

  return (
    <Drawer
      show={showDrawer}
      setShow={(value) => handleSetShow(value)}
      title={conversationId}
      expandable={true}
      showFooter
      // scrollable
      footerContent={
        questionsForClaire && questionsForClaire.length > 0 ? (
          <div className="flex items-center justify-start space-x-4 text-brand-700">
            <DropdownMenu
              id="ask-claire-dropdown"
              title="Select a topic"
              buttonText="Ask Claire"
              buttonType="text"
              buttonColor="text"
              direction="up"
              menuItems={questionsForClaire}
              handleClick={(v) => {
                console.log("Ask Claire", v);
              }}
            />
          </div>
        ) : null
      }
    >
      <div className="flex flex-col h-full">
        {/* <div className="flex-grow px-6 mx-auto my-auto"></div> */}
        <div
          id="conversation-container"
          className="h-full overflow-y-scroll scrollbar-default px-6 py-4 pb-14  mb-24 bg-gray-100 border-t border-gray-200"
        >
          <div className="space-y-4 flex flex-col">
            {conversation && conversation.length > 0 ? (
              conversation.map((message, index) => {
                const isBot = message.sender === "bot";

                return (
                  <div
                    id={index === conversation.length - 1 ? "last-message" : ""}
                    key={index}
                    className={`${
                      isBot ? "w-[80%]" : "w-[60%]"
                    } space-y-6 p-4 rounded-lg shadow-md ${
                      isBot ? "bg-indigo-100 self-start" : "bg-white self-end"
                    }`}
                  >
                    <div
                      className="text-sm text-gray-700 space-y-6"
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    ></div>
                    {/* <p className="text-sm text-gray-700">{message.message}</p> */}
                  </div>
                );
              })
            ) : (
              <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="text-sm text-gray-700">
                  How can I help you today?
                </p>
              </div>
            )}
            {gettingAnswer && (
              <div
                className={`w-[80%] p-4 rounded-lg shadow-md bg-indigo-100 self-start`}
              >
                <div className="flex items-center">
                  <Skeleton type="spiner" forButton className="inline" />{" "}
                  <p className="flex text-sm text-gray-700">
                    Let me get an answer for you. Keep in mind it could take a
                    few minutes for me to analyze all these bids.
                  </p>
                </div>
              </div>
            )}
            {/* <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="text-sm text-gray-700">
                  How can I help you today?
                </p>
                d
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="text-sm text-gray-700">
                  Do you need assistance with your bids?
                </p>
              </div> */}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ChatComponent;
