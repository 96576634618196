import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Files = {
  async uploadNewSolicitationDocs(file, type) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // try {
    console.log("uploadNewSolicitationDocs before api", file);
    const data = new FormData();

    data.append("file", file);
    data.append("companyId", profile.companyId);
    //   data.append("company", profile.company);
    data.append("tenantId", profile.tenantId);
    data.append("type", type);
    let response = await postRequest("upload/bid-request-docs", data);
    if (response) {
      console.log("uploadNewSolicitationDocs", response.data.data);
      return response.data.data;
    } else {
      console.log("uploadNewSolicitationDocs failed", response);
      return false;
    }
    // } catch (e) {
    //   console.log(e);
    // }
  },
  async getFileById(id, documentType, solicitationId) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    let data = {
      id: id,
      companyId: profile.companyId,
      tenantId: profile.tenantId,
      solicitationId: solicitationId,
      // path: path,
      documentType: documentType,
    };
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("upload/get-file-by-id", data);
    if (response) {
      console.log("getFileById", response.data.data);
      return response.data.data;
    } else {
      console.log("getFileById failed", response);
      return false;
    }
  },
  async getFileDownloadUrl(id) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    let data = {
      docId: id,
      companyId: profile.companyId,
      tenantId: profile.tenantId,
    };
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("upload/get-file-by-id", data);
    if (response) {
      console.log("getFileById", response.data.data.url);
      return response.data.data.url;
    } else {
      console.log("getFileById failed", response);
      return false;
    }
  },
  async convertToExcel(tableData) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    let data = {
      tableData,
    };
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("files/convertToExcel", data);
    if (response) {
      // console.log("convertToExcel", response.data.data);
      const base64 = response.data.data;
      const binary = atob(base64); // Decode the base64 data
      const arrayBuffer = new Uint8Array(binary.length).map((_, i) =>
        binary.charCodeAt(i)
      ).buffer; // Convert the binary data to an ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "responses.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Error:", response.statusText);
      return false;
    }
  },
  async convertToExcelForV2Bids(solicitation) {
    const { responses, name, createdAt } = solicitation;
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    let data = {
      tableData: responses,
    };
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("files/convertToExcelForV2Bids", data);
    if (response) {
      // console.log("convertToExcel", response.data.data);
      const base64 = response.data.data;
      const binary = atob(base64); // Decode the base64 data
      const arrayBuffer = new Uint8Array(binary.length).map((_, i) =>
        binary.charCodeAt(i)
      ).buffer; // Convert the binary data to an ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Error:", response.statusText);
      return false;
    }
  },
  async convertToPDFforV2Bids(solicitation) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    const { responses, name, createdAt } = solicitation;
    let data = { tableData: responses, name, createdAt };

    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    try {
      let response = await postRequest("files/download-v2-responses", data);

      if (response && response.data) {
        console.log("convertToPDF", response.data.data.length);

        // Assuming response.data.data is base64 encoded
        const base64Data = response.data.data;

        if (!base64Data) {
          console.error("No data received for PDF.");
          return;
        }

        // Decode base64 to binary data
        const binaryData = atob(base64Data); // Decode base64 string into raw binary data
        const byteArray = new Uint8Array(binaryData.length);

        // Convert binary data into Uint8Array
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i);
        }

        // Create a Blob from the binary data
        const blob = new Blob([byteArray], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element for triggering the download
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.pdf`; // Set filename for download

        // Trigger the download
        document.body.appendChild(a);
        a.click();
        a.remove();

        // Revoke the object URL to free up memory
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Error:", response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Error during PDF download:", error);
      return false;
    }
  },
};
