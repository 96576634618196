import { LightBulbIcon } from "@heroicons/react/20/solid";
import React from "react";
import Button from "../elements/Button";
import { Icon } from "@iconify/react";
import Fade from "react-reveal/Fade";

export default function Tip(props) {
  const showClose = props.showClose ? props.showClose : false;
  const [isOpen, setIsOpen] = React.useState(true);

  const { children, color, icon, noIcon, className, ...rest } = props;
  const colSpan = props.colSpan ? props.colSpan : false;
  const noMargin = props.noMargin ? props.noMargin : false;
  //   const classes = useStyles();
  const getColor = () => {
    if (color === undefined) {
      return (
        " bg-brand-50 border-2 border-dashed border-brand-500 " +
        props.className
      );
    }
    switch (color) {
      case "primary":
        return " bg-primary-50 border-2 border-dashed border-primary-500";
      case "secondary":
        return " bg-brand-50 border-2 border-dashed border-brand-500";
      case "emerald":
        return " bg-emerald-50 border-2 border-dashed border-emerald-500";
      case "gray":
        return " bg-gray-50 border-2 border-dashed border-gray-200";
      case "tertiary":
        return " bg-tertiary-200  text-primary-600 borde-2 border-dashed border-tertiary-500";
      case "accent":
        return " bg-accent-500  text-white-600";
      case "green":
        return " bg-green-50 border-2 border-dashed border-green-500";
      case "purple":
        return " bg-purple-100 text-primary-600  border-dashed border-2 border-purple-600";
      case "orange":
        return " bg-orange-200  text-primary-600";
      case "amber":
        return " bg-amber-50 border-2 border-dashed border-amber-500 text-primary-600";
      case "red":
        return " bg-red-50 border-2 border-dashed border-red-500  text-primary-600";
      case "light-purple":
        return " bg-primary-200  text-purple-500";
      case "lime":
        return " bg-lime-500  text-primary-600 ";
      case "white":
        return " bg-white border-gray-200  text-slate-200 ";
      case "text":
        return "text-slate-500  mx-3 p-1";
      case "link":
        return "text-blue-500 ";
      default:
        return " bg-primary-500 text-primary-600" + props.className;
    }
  };
  // if (!isOpen) return null;
  return (
    <Fade when={isOpen} unmountOnExit duration={500}>
      {isOpen && (
        <div
          className={`relative flex items-top justify-between ${
            noMargin == true ? "my-0" : "my-6"
          } ${colSpan ? "col-span-" + colSpan : ""}`}
        >
          <div
            className={`flex-1 p-2 ${getColor()} text-sm text-slate-600 pr-5`}
          >
            <div className="flex-1">
              {icon ? (
                icon
              ) : noIcon ? (
                ""
              ) : (
                <LightBulbIcon className="my-auto inline-flex w-5 h-5 mr-1 fill-yellow-400" />
              )}
              {children}
            </div>
            {showClose && (
              <div className="absolute top-0 right-0 text-gray-800 hover:text-gray-900">
                <Button iconButton handleClick={() => setIsOpen(false)}>
                  <Icon icon="carbon:close" className="h-5 w-5" />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Fade>
  );
}
