import React from "react";
import { useEffect, useState } from "react";
import BoardCard from "./card";
import CardColumn from "./column";
import { DragDropContext } from "@hello-pangea/dnd";
import { Solicitations } from "../../../modules/_solicitations";
import { Icon } from "@iconify/react";

export default function Board(props) {
  const [selectedCard, setSelectedCard] = useState(null);

  const [boardData, setBoardData] = useState([]);
  const [type, setType] = useState("");

  useEffect(() => {
    console.log("Board data props", props.boardData);
  }, [props.columns, props.type]);
  useEffect(() => {
    console.log("Board data props", props.boardData);
    setBoardData(props.boardData);
  }, [props.boardData]);
  useEffect(() => {
    console.log("Board type props", props.type);
    setType(props.type);
  }, [props.type]);

  console.log("Board props", props);

  const sortColumn = (columnTitle, sortSettings) => {
    //find the column in the boardData and sort the cards
    console.log("sortColumn", columnTitle, sortSettings);
    let newBoardData = [...boardData];
    newBoardData = newBoardData.map((column) => ({
      ...column,
      cards: column.cards.sort(/* sorting function */),
    }));
    let column = newBoardData.find((c) => c.title === columnTitle);

    if (column) {
      column.cards.sort((a, b) => {
        if (sortSettings.sortType === "asc") {
          if (a[sortSettings.sortField] < b[sortSettings.sortField]) {
            return -1;
          }
          if (a[sortSettings.sortField] > b[sortSettings.sortField]) {
            return 1;
          }
          return 0;
        } else {
          if (a[sortSettings.sortField] > b[sortSettings.sortField]) {
            return -1;
          }
          if (a[sortSettings.sortField] < b[sortSettings.sortField]) {
            return 1;
          }
          return 0;
        }
      });
    }
    setBoardData(newBoardData);
  };

  const handleOnDragEnd = (result) => {
    console.log("handleOnDragEnd", result);
    props.handleOnDragEnd(result);
  };
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div
        id="board"
        className="flex flex-grow overflow-x-auto
       flex-row pb-2
       scrollbar-default"
      >
        {boardData &&
          boardData.length > 0 &&
          boardData.map((column) => (
            <CardColumn
              empty={false}
              column={column}
              showAddColumn={false}
              type={type}
              isDragDisabled={props.isDragDisabled}
              sortColumn={(colTitle, sortSettings) => {
                console.log("sortColumn", colTitle, sortSettings);
                sortColumn(column.title, sortSettings);
              }}
            >
              {/* <div className="flex flex-col h-full w-full space-y-3 pb-6 px-3 pt-4 overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100"> */}
              {column?.cards && column?.cards.length > 0
                ? column.cards.map((card, index) => (
                    <BoardCard
                      key={card._id}
                      id={card._id}
                      index={index}
                      data={card}
                      cardKey={
                        "P-" +
                        card.projectId?.GlobalId +
                        "-BR-" +
                        card.incrementId
                      }
                      title={card.name}
                      subTitle={card.tenantId?.tenantTitle}
                      description={card.Description}
                      status={
                        type == "workflow"
                          ? card.status
                          : card.workflow_status &&
                            card.workflow_status.length > 0 &&
                            Solicitations.getCurrentWorkflow(
                              card.workflow_status
                            )?.name
                      }
                      color={
                        card.isForTesting && card.isForTesting == true
                          ? "primary"
                          : card.responses &&
                            card.responses.length === card.invitations?.length
                          ? "emerald"
                          : card.responses.length / card.invitations.length <=
                              0.5 &&
                            new Date(card.createdAt).setDate(
                              new Date(card.createdAt).getDate() + 1
                            ) < Date.now()
                          ? "red"
                          : "secondary"
                      }
                      burden={card.score}
                      effort={card.updatedAt}
                      identifier={"BR"}
                      // total={card.responses.length + " bids"}
                      total={() => {
                        return (
                          <div className="space-x-1 flex items-center">
                            {card.winner_notified && (
                              <div>
                                <Icon
                                  icon="mdi:envelope"
                                  className="text-emerald-600"
                                />
                              </div>
                            )}
                            {card.winner_selected && (
                              <div>
                                <Icon
                                  icon="mdi:trophy"
                                  className="text-emerald-600"
                                />
                              </div>
                            )}

                            <div>{card.responses.length} bids</div>
                          </div>
                        );
                      }}
                      isDragDisabled={props.isDragDisabled}
                      handleCardClick={props.handleCardClick}
                      createDate={card.createdAt}
                    />
                  ))
                : null}
            </CardColumn>
          ))}
        <CardColumn empty={true} />
      </div>
    </DragDropContext>
  );
}
