import React, { useState } from "react";
import Skeleton from "../components/Skeleton";
import { audit } from "../modules/_audit";

// SAMPLE BUTTON USAGE
// NOTE: Submit, Save, etc is always purple, cancel, close, exit is always white. Use green for action buttons at top of page
// and the middle of the page for <Empty> buttons

{
  /* 
    <Button
      enabled={!loader} //I don't think this is actually used
      loader={loader}
      type="submit"
      iconButton={true} //is only for the small circle button for clikcing on icons
      rounded //this means fully rounded like oval. Leave off for default rounded and iconButton
      disabled={disabled} //this is for disabling the button
      color="purple" // purple, green, white, text, link
      size="sm" //sm, md, lg  --> this creates the built in padding on top and bottom of the button
      fullWidth //this means takes up full width of parent container. leave off for default width    
      handleClick={onRegister}
      audit={true} //use this to audit the action being performed. Can be left off if not needed
      action="Invite User" //name of the action being performed when auditing is enabled. Only needed if audit is true
      details={{ user: fullName, email: email, role: role }} //additional details to be logged when auditing is enabled
      >
        <LockIcon className="h-5 w-5 mr-2" aria-hidden="true" />  Sign in
    </Button> 
  */
}

// Still to-do:
// Icon Button: className="inline-flex items-center p-2  border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
// Pill button: className="ml-2 inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none "

function Button(props) {
  const loader = props.loader;
  const loading = props.loader;
  const disabled = props.disabled;
  const full = props.fullWidth;
  const isAudit = props.audit;
  const action = props.action;
  const iconButton = props.iconButton;
  const forButton = props.iconButton ? false : true;
  const color = props.color ? props.color : "default";
  const auditDetails = props.details ? props.details : {};
  const rounded = props.rounded;
  const ref = props.ref ? props.ref : null;
  const className = props.className ? props.className : "";
  const shadow = props.shadow ? props.shadow : false;
  const zoom = props.zoom ? props.zoom : false;

  // console.info("Button props", auditDetails, props.details);

  const handleAuditClick = (e) => {
    console.log("audit click", action, auditDetails);
    audit.logAction(action, auditDetails);
    props.handleClick(e);
  };

  const buttonStyle = (color) => {
    if (disabled == true && color !== "text") {
      return "btn bg-gray-200 text-slate-400 cursor-not-allowed";
    } else {
      switch (color) {
        case "primary":
          return "btn bg-primary-800 hover:bg-primary-900 text-white";
        case "secondary":
          return "btn bg-brand-600 hover:bg-brand-600  text-white";
        case "tertiary":
          return "btn bg-tertiary-600 hover:bg-tertiary-700 text-white";
        case "accent":
          return "btn bg-accent-600 hover:bg-accent-700 text-white";
        case "secondary-accent":
          return "btn bg-secondaryAccent-600 hover:bg-secondaryAccent-700 text-secondaryAccent-100";
        case "indigo":
          return "btn bg-brand-600 hover:bg-brand-700 text-white";
        case "emerald":
          return "btn bg-emerald-600 hover:bg-emerald-700 text-white";
        case "green":
          return "btn bg-green-600 hover:bg-green-700 text-white";

        case "purple":
          return "btn bg-purple-600 hover:bg-purple-700 text-white";
        case "orange":
          return "btn bg-orange-600 hover:bg-orange-700 text-white";
        case "red":
          return "btn bg-red-600 hover:bg-red-700 text-white";
        case "light-purple":
          return "btn bg-primary-200 hover:bg-purple-250 text-purple-500";
        case "lime":
          return "btn bg-brandGreen-500 hover:bg-brandGreen-600 text-white ";
        case "white":
          return "btn bg-white border-gray-200 hover:border-gray-300 text-slate-600 ";
        // className="btn-sm border-gray-200 hover:border-gray-300 text-slate-600"
        case "transparent":
          return " bg-transparent shadow-none ";

        case "outline":
          return "btn border border-brand-500 hover:border-brand-500 hover:bg-brand-50 hover:bg-opacity-80 text-brand-500";
        case "outline-white":
          return "btn border border-white  text-white";

        case "outline-primary":
          return "btn border border-primary-500 hover:border-primary-500 hover:bg-primary-50 text-primary-500";
        case "text":
          return "text-slate-500 shadow-none  hover:text-slate-700 mx-3 p-1";
        case "text-hover":
          return "text-slate-500 hover:text-primary-500 hover:bg-gray-50 mx-3 p-1";
        case "text-dark":
          return "text-slate-600 hover:text-primary-700 hover:bg-gray-100 mx-3 p-1";
        case "text-secondary":
          return "shadow-none  text-brand-500 hover:text-brand-600 mx-3 p-1";
        case "text-brand":
          return "shadow-none  text-brand-500 hover:text-brand-600 mx-3 p-1";
        case "text-accent":
          return "shadow-none  text-accent-500 hover:text-accent-600 mx-3 p-1";
        case "text-green":
          return "shadow-none  text-brandGreen-700 hover:text-brandGreen-800 mx-3 p-1";
        case "link":
          return "btn shadow-none text-brand-600 hover:text-brand-700";
        default:
          return (
            "btn bg-primary-800 hover:bg-primary-900 text-white " +
            props.className
          );
      }
    }
  };

  const buttonSize = (buttonSize) => {
    switch (buttonSize) {
      case "xs":
        return "btn-xs rounded px-1.5 py-1 text-sm font-semibold ";
      case "sm":
        return "btn-sm  rounded px-2 py-1.5 text-sm font-semibold ";
      case "md":
        return "btn-md rounded px-2.5 py-2 text-sm font-semibold  ";
      case "lg":
        return "btn-lg rounded-md  px-3 py-2.5 text-sm font-semibold";
      case "xl":
        return "btn-xl rounded-md  px-3.5 py-3 text-sm font-semibold";
      default:
        return "btn-md rounded px-2.5 py-2 text-sm font-semibold";
      // case "xs":
      //   return "px-2.5 py-1.5 text-xs ";
      // case "sm":
      //   return "btn-sm text-xs py-1.5 font-normal ";
      // case "md":
      //   return "btn-md text-sm py-1.5";
      // case "lg":
      //   return "btn-lg text-base py-2.5";
      // default:
      //   return "btn-md text-sm py-1.5";
      /*
      export default function Example() {
  return (
    <>

 <button
        type="button"
        className="rounded bg-brand-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
      >
        XS
      </button>
      <button
        type="button"
        className="rounded bg-brand-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
      >
        SM
      </button>
      <button
        type="button"
        className="rounded-md  px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
      >
        MD
      </button>
      <button
        type="button"
        className="rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
      >
        LG
      </button>
      <button
        type="button"
        className="rounded-md  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
      >
        XL
      </button>
    </>
  )
}

      */
    }
  };

  return (
    // <button
    //   type="button"
    //   class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mb-2"
    // >
    //   <svg
    //     class="w-4 h-4 mr-2 -ml-1"
    //     aria-hidden="true"
    //     focusable="false"
    //     data-prefix="fab"
    //     data-icon="facebook-f"
    //     role="img"
    //     xmlns="http://www.w3.org/2000/svg"
    //     viewBox="0 0 320 512"
    //   >
    //     <path
    //       fill="currentColor"
    //       d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"
    //     ></path>
    //   </svg>
    //   Sign in with Facebook
    // </button>
    <button
      className={
        iconButton
          ? `rounded-full p-2 ${!disabled && "bg-transparent"} ${
              !disabled && "hover:bg-slate-100"
            } ${disabled && "cursor-not-allowed"}`
          : `inline-flex items-center ${buttonStyle(color)} ${buttonSize(
              props.size
            )}  ${full ? " w-full " : ""} ${
              rounded && rounded == true ? " rounded-full px-4" : "rounded px-4"
            } ${shadow && "shadow-sm shadow-slate-400"} ${className} `
      }
      onClick={(e) =>
        props.handleClick
          ? isAudit
            ? handleAuditClick(e)
            : props.handleClick(e)
          : isAudit
          ? audit.logAction(action, auditDetails)
          : null
      }
      disabled={disabled}
    >
      {loading && (
        <Skeleton
          type="spinner"
          color="gray"
          fill="purple"
          forButton={iconButton ? false : forButton}
          forIcon={iconButton ? true : false}
        />
      )}
      {iconButton || zoom ? (
        <div className={"transform hover:scale-110 duration-150 ease-in-out"}>
          {props.children}
        </div>
      ) : (
        props.children
      )}
    </button>
  );
}

export default Button;
