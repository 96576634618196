import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import Badge from "../../components/Badge";
import MenuTag from "../../components/MenuTag";
import QuestionToolTip from "../../components/QuestionToolTip";
import { utils } from "../../modules/_utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({
  tabs,
  count,
  icon,
  secure,
  spacing,
  showDelete,
  ...props
}) {
  !spacing && (spacing = "md");
  const setSelectedTab = props.setSelectedTab;
  const selectedTab = props.selectedTab;
  const scrollContainerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const isVisible = (tab) => {
    if (secure && secure === true) {
      return (
        tab.view?.includes(props.profile?.role) ||
        props.profile?.sysRole === "Super Admin"
      );
    } else {
      return true;
    }
  };
  // Check for overflow on component mount and on window resize
  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [tabs]);
  // Check if the tabs are overflowing
  const checkOverflow = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  };
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md cursor-pointer"
          defaultValue={
            tabs && tabs.length > 0 && tabs.find((tab) => tab.current)?.name
          }
          onChange={(e) => {
            setSelectedTab(e.target.value);
          }}
        >
          {tabs.map((tab) => {
            if (isVisible(tab)) {
              return <option key={tab.name}>{tab.name}</option>;
            }
          })}
        </select>
      </div>
      <div className="hidden sm:flex items-center relative ">
        {isOverflowing && (
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-0 bottom-0 z-10 bg-white"
          >
            &lt;
          </button>
        )}
        <div className={`flex-1 overflow-hidden ${isOverflowing && " mx-8 "}`}>
          <div
            ref={scrollContainerRef}
            className="flex space-x-4 overflow-x-auto scrollbar-hidden"
          >
            {tabs.map((tab, index) => {
              if (isVisible(tab)) {
                return (
                  <a
                    key={tab.name}
                    onClick={() => setSelectedTab(tab.name, index)}
                    className={classNames(
                      tab.color
                        ? tab.color
                        : tab.name === selectedTab
                        ? "bg-brand-100 text-brand-700"
                        : "text-slate-500 hover:text-slate-700",
                      "flex whitespace-nowrap items-center space-x-2 px-3 py-2 font-medium text-sm rounded-md cursor-pointer capitalize"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.icon && (
                      <span className="inline-flex">
                        <Icon icon={tab.icon} />
                      </span>
                    )}
                    <span>
                      {props.cleanUpKeys
                        ? props.cleanUpKeys(tab.name)
                        : tab.name}
                    </span>
                    <span> {tab.count}</span>
                    {tab.status && (
                      <>
                        <MenuTag status={tab.status.toUpperCase()} />
                        {tab.tooltip && (
                          <QuestionToolTip
                            color="brand"
                            field="Free Preview"
                            text={utils.getTooltipText(tab.status)}
                          />
                        )}
                      </>
                    )}
                  </a>
                );
              }
            })}
          </div>
        </div>
        {isOverflowing && (
          <button
            onClick={scrollRight}
            className="absolute right-0 top-0 bottom-0 z-10 bg-white"
          >
            &gt;
          </button>
        )}
      </div>
    </div>
  );
}
